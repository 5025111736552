import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../components/utils/Button';
import countryAction from '../../redux/country/actions';
import ProfileActions from "../../redux/profile/actions"
import Alert from '../../components/utils/Alert';

const { getCountry } = countryAction;
const { closeEditForm, editProfileList,resetMessage,getProfileData } = ProfileActions;

const ProfileEditForm = ({ profileData }) => {
    const dispatch = useDispatch();
    const countryResult = useSelector((state) => state.country.countryResult);
    const {editError,editMessage,buttonLoading} = useSelector((state) => state.profile);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const [editedData, setEditedData] = useState({
        name: '',
        email: '',
        number: '',
        address: '',
        city: '',
        state: '',
        zipCode: '',
        countryID: '',
    });

    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({
        defaultValues: editedData
    });

    useEffect(() => {
        dispatch(getCountry());
        reset(editedData);
    }, [dispatch, reset, editedData]);

    useEffect(() => {
        if (editedData) {
            Object.keys(editedData).forEach(key => {
                setValue(key, editedData[key]);
            });
        }
    }, [editedData, countryResult, setValue]);
    useEffect(()=>{
        if(editMessage){
            setTimeout(()=>{
                dispatch(getProfileData())
            },1500)
        }
    },[editMessage])

    useEffect(() => {
        if (editError !== null && editError !== undefined) {
            displayAlert(editError, 'alert-danger');
        }
        if (editMessage !== null && editMessage !== undefined) {
            displayAlert(editMessage, 'alert-success');
        }
    }, [editError, editMessage]);


    useEffect(() => {
        if (profileData) {
            const data = {
                name: profileData.Name,
                email: profileData.Email,
                number: profileData.MobileNumber,
                address: profileData.AddressLine1,
                city: profileData.City,
                state: profileData.State,
                zipCode: profileData.Zipcode,
                countryID: profileData.Country,
            };
            setEditedData(data);
            reset(data);
        }
    }, [profileData, reset]);

    const handleNumber = (e) => {
        const { value } = e.target;
        if (!(e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46)) {
            e.preventDefault();
        }
        if (value.includes('.')) {
            e.preventDefault();
        }
    };

    const handleBack = () => {
        dispatch(closeEditForm());
    };
    const handleSubmitform = (data) => {
        const customerId = profileData.ID
        dispatch(editProfileList(customerId, data))
    };

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(resetMessage());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(resetMessage());
    }

    return (

        <div className="flex min-h-full flex-1 flex-col justify-center px-6 shadow-md lg:px-8 profile-edit-bg mt-[12px;]">
           
            <div className="sm:mx-auto sm:w-full sm:max-w-sm mb-[6px]">
                {showAlert && <div className='mt-[35px]'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
                <h2 className="mt-[.8rem] text-center text-2xl font-[600] leading-9 tracking-tight text-gray-900">
                    Edit Profile
                </h2>
            </div>

            <div className="mt-5 ">
                <form className="space-y-2" action="#" onSubmit={handleSubmit(handleSubmitform)}>
                    <div className='grid grid-cols-2 gap-3'>
                    <div>
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900"> Name </label>
                        <div className="mt-2">
                            <input id="name" name="name" type="text" autoComplete="name" {...register('name', { required: true })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                            />
                            {errors.name && <div className="text-red-500 text-left text-xs">Name is required!</div>}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900"> Email address </label>
                        <div className="mt-2">
                            <input id="email" name="email" type="text" autoComplete="email" {...register('email', {
                                required: true,
                                pattern: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/
                            })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                            />
                            {errors.email && <div className="text-red-500 text-left text-xs">{errors.email.type === "required" ? "Email address is required!" : "Invalid Email Format!"}</div>}
                        </div>
                    </div>
                    </div>
                  
                    <div className='grid grid-cols-2 gap-3'>
                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="number" className="block text-sm font-medium leading-6 text-gray-900">
                                Phone Number
                            </label>
                        </div>
                        <div className="mt-2">
                            <input id="number" name="number" type="number" maxLength={10} autoComplete="number" {...register('number', { required: true })}
                                onKeyDown={handleNumber}
                                onInput={(e) => {
                                    if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength);
                                }}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                            />
                            {errors.number && <div className="text-red-500 text-left text-xs">Phone Number is required!</div>}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="address" className="block text-sm font-medium leading-6 text-gray-900"> Address </label>
                        <div className="mt-2">
                            <input id="address" name="address" type="text" autoComplete="address" {...register('address', { required: true })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                            />
                            {errors.address && <div className="text-red-500 text-left text-xs">Address is required!</div>}
                        </div>
                    </div>
                    </div>

                    <div className='grid grid-cols-2 gap-3'>
                    <div>
                        <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900"> City </label>
                        <div className="mt-2">
                            <input id="city" name="city" type="text" autoComplete="city" {...register('city', { required: true })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                            />
                            {errors.city && <div className="text-red-500 text-left text-xs">City is required!</div>}
                        </div>
                    </div>

                    <div>
                        <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900"> State </label>
                        <div className="mt-2">
                            <input id="state" name="state" type="text" autoComplete="state" {...register('state', { required: true })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                            />
                            {errors.state && <div className="text-red-500 text-left text-xs">State is required!</div>}
                        </div>
                    </div>
                    </div>

                    <div className='grid grid-cols-2 gap-3'>
                    <div>
                        <div className="flex items-center justify-between">
                            <label htmlFor="countryID" className="block text-sm font-medium leading-6 text-gray-900">
                                Select Country
                            </label>
                        </div>
                        <select name='countryID' {...register('countryID', { required: true })} id="countryID"
                            className="block w-full mt-2 p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500">
                            <option value="">Select Country</option>
                            {countryResult && countryResult.map(country => (
                                <option value={country.ID} key={country.ID}>{country.CountryName}</option>
                            ))}
                        </select>
                        {errors.countryID && <div className="text-red-500 text-left text-xs">Country is required!</div>}
                    </div>

                    <div>
                        <label htmlFor="zipCode" className="block text-sm font-medium leading-6 text-gray-900"> ZipCode </label>
                        <div className="mt-2">
                            <input id="zipCode" name="zipCode" type="text" autoComplete="zipCode" {...register('zipCode', { required: true })}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                            />
                            {errors.zipCode && <div className="text-red-500 text-left text-xs">ZipCode is required!</div>}
                        </div>
                    </div>
                    </div>

                    <div className='grid grid-cols-2 gap-3 py-4'>
                    <div>
                        <Button loading={buttonLoading} title={'Save'} buttonClass="flex w-full justify-center rounded-md bg-[#223b8b] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#223b8bda] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" buttonType='submit' />
                    </div>
                    <button type="button" className="w-full text-white bg-[#565556] hover:bg-[#662e91] focus:ring-4 focus:outline-none focus:ring-[#565556] font-medium rounded-lg text-sm px-5 py-2.5 text-center" onClick={handleBack}>Back</button>
                    </div>

                    
                    
                </form>
            </div>
        </div>
    );
}

export default ProfileEditForm;
