import actions from "./actions";

const initState = {
    planResult: [],
    loading: false,
    planError: null,
    planMessage: null,
    createOrderLoading: false,
    createOrderError: null,
    createOrderMessage: null,
    createOrderData: new Object(),
    processOrderLoading: false,
    processOrderError: null,
    processOrderMessage: null,
    failedOrderLoading: false,
    failedOrderError: null,
    failedOrderMessage: null,
    completeOrderLoading: false,
    completeOrderError: null,
    completeOrderMessage: null,
    selectedId:null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.SELECTED_NETWORK:
            return {
                ...state,
                selectedId:action.selectedId,
            };  

        case actions.COMPLETE_ORDER:
            return {
                ...state,
                completeOrderLoading: true,
                completeOrderError: null,
                completeOrderMessage: null,
            };
        case actions.COMPLETE_ORDER_SUCCESS:
            return {
                ...state,
                completeOrderLoading: false,
                completeOrderError: null,
                completeOrderMessage: action.payload.message,
            };
        case actions.COMPLETE_ORDER_FAILED:
            return {
                ...state,
                completeOrderLoading: false,
                completeOrderError: action.payload.error,
                completeOrderMessage: null,
            };


        case actions.FAILED_ORDER:
            return {
                ...state,
                failedOrderLoading: true,
                failedOrderError: null,
                failedOrderMessage: null,
            };
        case actions.FAILED_ORDER_SUCCESS:
            return {
                ...state,
                failedOrderLoading: false,
                failedOrderError: null,
                failedOrderMessage: action.payload.message,
            };
        case actions.FAILED_ORDER_FAILED:
            return {
                ...state,
                failedOrderLoading: false,
                failedOrderError: action.payload.error,
                failedOrderMessage: null,
            };

        case actions.PROCESS_ORDER:
            return {
                ...state,
                processOrderLoading: true,
                processOrderError: null,
                processOrderMessage: null,
            };
        case actions.PROCESS_ORDER_SUCCESS:
            return {
                ...state,
                processOrderLoading: false,
                processOrderError: null,
                processOrderMessage: action.payload.message,
            };
        case actions.PROCESS_ORDER_FAILED:
            return {
                ...state,
                processOrderLoading: false,
                processOrderError: action.payload.error,
                processOrderMessage: null,
            };

        case actions.RESET_RECHARGE:
            return {
                ...state,
                planResult: [],
                selectedId:null,
                createOrderData: new Object(),
            };

        case actions.CREATE_ORDER:
            return {
                ...state,
                createOrderLoading: true,
                createOrderError: null,
                createOrderMessage: null,
            };
        case actions.CREATE_ORDER_SUCCESS:
            return {
                ...state,
                createOrderLoading: true,
                createOrderError: null,
                createOrderMessage: action.payload.message,
                createOrderData: action.payload.data
            };
        case actions.CREATE_ORDER_FAILED:
            return {
                ...state,
                createOrderLoading: false,
                createOrderError: action.payload.error,
                createOrderMessage: null,
            };

        case actions.GET_PLAN_BY_NETWORK:
            return {
                ...state,
                loading: true,
                planMessage: null,
                planError: null,
                selectedId:action.network,
            };
        case actions.GET_PLAN_BY_NETWORK_SUCCESS:
            return {
                ...state,
                loading: false,
                planResult: action.planResult,
                planMessage: null,
                planError: null,
            };
        case actions.GET_PLAN_BY_NETWORK_FAILED:
            return {
                ...state,
                loading: false,
                planError: action.planError,
                planMessage: null,
            };

        default:
            return state;
    }
}
