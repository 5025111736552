import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PaymentFailedGif from '../../assets/gif/payment-failed.gif'
import UtilityActions from '../../redux/utility/action';

const { failedUtilityOrder } = UtilityActions;

const UtilityPaymentFailed = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    localStorage.removeItem('payment');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const payment_id = urlParams.get('payment_id')

    const data = {
      paymentTxnID: payment_id
    }
    dispatch(failedUtilityOrder(data));
  }, []);

  const handleTryAgain = () => {
    window.location.href = '/';
  }

  return (
    <>
      <section className='flex items-center justify-center' style={style.section}>
        <div className="container mx-auto h-full flex items-center justify-center">
          <div className='w-1/3'>
            <div className='w-full rounded-md shadow-lg payment-failed px-card'>
              <div className='w-[200px] mx-auto '>
                <img src={PaymentFailedGif} style={style.gifStyle} />
              </div>
              <div className='pt-[7px] '>
                <h1 className='text-center text-4xl font-medium'>Your payment was Failed</h1>
                <p className='text-center pt-[15px]'>
                  Thank you for attempting the payment; we will be in contact with more details shortly.
                </p>

                <div className='text-center mt-[10px]'>
                  <button className='btn-failed rounded' onClick={() => handleTryAgain()}>Try again later</button>
                </div>

              </div>

            </div>
          </div>

        </div>
      </section>
    </>
  )
}

const style = {
  gifStyle: {
    width: '200px',
    height: '200px'
  },
  section: {
    minHeight: "calc(100vh - 84px)",
    background: "#ecf0f15e"
  }
}

export default UtilityPaymentFailed