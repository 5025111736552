const actions = {
    GET_POFILE_DATA: "GET_POFILE_DATA",
    GET_POFILE_DATA_SUCCESS: "GET_POFILE_DATA_SUCCESS",
    GET_POFILE_DATA_FAILED: "GET_POFILE_DATA_FAILED",
    OPEN_EDIT_FORM: "OPEN_EDIT_FORM",
    CLOSE_EDIT_FORM: "CLOSE_EDIT_FORM",
    EDIT_PROFILE_LIST: "EDIT_PROFILE_LIST",
    EDIT_PROFILE_LIST_SUCCESS: "EDIT_PROFILE_LIST_SUCCESS",
    EDIT_PROFILE_LIST_FAILED: "EDIT_PROFILE_LIST_FAILED",
    DELETE_CUSTOMER_ACCOUNT: "DELETE_CUSTOMER_ACCOUNT",
    DELETE_CUSTOMER_ACCOUNT_SUCCESS: "DELETE_CUSTOMER_ACCOUNT_SUCCESS",
    DELETE_CUSTOMER_ACCOUNT_FAILED: "DELETE_CUSTOMER_ACCOUNT_FAILED",
    CHANGE_CUSTOMER_PASSWORD: 'CHANGE_CUSTOMER_PASSWORD',
    CHANGE_CUSTOMER_PASSWORD_SUCCESS: 'CHANGE_CUSTOMER_PASSWORD_SUCCESS',
    CHANGE_CUSTOMER_PASSWORD_FAILED: 'CHANGE_CUSTOMER_PASSWORD_FAILED',
    RESET_MESSAGE: 'RESET_MESSAGE',
    GET_ORDER_DATA: "GET_ORDER_DATA",
    GET_ORDER_DATA_SUCCESS: "GET_ORDER_DATA_SUCCESS",
    GET_ORDER_DATA_FAILED: "GET_ORDER_DATA_FAILED",
    RETRY_ORDER: "RETRY_ORDER",
    RETRY_ORDER_SUCCESS: "RETRY_ORDER_SUCCESS",
    RETRY_ORDER_FAILED: "RETRY_ORDER_FAILED",

    retryOrder: (id) => ({
        type: actions.RETRY_ORDER,
        payload: { id }
    }),
    retryOrderSuccess: (message) => ({
        type: actions.RETRY_ORDER_SUCCESS,
        payload: { message }
    }),
    retryOrderFailed: (error) => ({
        type: actions.RETRY_ORDER_FAILED,
        payload: { error }
    }),

    getProfileData: () => ({
        type: actions.GET_POFILE_DATA,
    }),
    getProfileDataSuccess: (profileDataResult) => ({
        type: actions.GET_POFILE_DATA_SUCCESS,
        profileDataResult
    }),
    getProfileDataFailed: (profileError) => ({
        type: actions.GET_POFILE_DATA_FAILED,
        profileError
    }),
    openEditForm: () => ({
        type: actions.OPEN_EDIT_FORM
    }),
    closeEditForm: () => ({
        type: actions.CLOSE_EDIT_FORM
    }),
    editProfileList: (Id, data) => ({
        type: actions.EDIT_PROFILE_LIST,
        payload: { Id, data }
    }),
    editProfileListSuccess: (message) => ({
        type: actions.EDIT_PROFILE_LIST_SUCCESS,
        payload: { message }
    }),
    editProfileListFailed: (error) => ({
        type: actions.EDIT_PROFILE_LIST_FAILED,
        payload: { error }
    }),

    deleteCustomerAccount: (id) => ({
        type: actions.DELETE_CUSTOMER_ACCOUNT,
        payload: { id },
    }),
    deleteCustomerAccountSuccess: (message) => ({
        type: actions.DELETE_CUSTOMER_ACCOUNT_SUCCESS,
        payload: { message },
    }),
    deleteCustomerAccountFailed: (error) => ({
        type: actions.DELETE_CUSTOMER_ACCOUNT_FAILED,
        payload: { error },
    }),

    changeCustomerPassword: (data) => ({
        type: actions.CHANGE_CUSTOMER_PASSWORD,
        payload: { data },
    }),
    changeCustomerPasswordSuccess: (message) => ({
        type: actions.CHANGE_CUSTOMER_PASSWORD_SUCCESS,
        payload: { message },
    }),
    changeCustomerPasswordFailed: (error) => ({
        type: actions.CHANGE_CUSTOMER_PASSWORD_FAILED,
        payload: { error },
    }),

    resetMessage: () => ({
        type: actions.RESET_MESSAGE,
    }),

    getOrderData: () => ({
        type: actions.GET_ORDER_DATA,
    }),
    getOrderDataSuccess: (orderDataResult) => ({
        type: actions.GET_ORDER_DATA_SUCCESS,
        orderDataResult
    }),
    getOrderDataFailed: (profileError) => ({
        type: actions.GET_ORDER_DATA_FAILED,
        profileError
    }),

}

export default actions;