import actions from './actions';
const initState = {
    loading: false,
    profileMessage: null,
    profileError: null,
    profileDataResult: [],
    buttonLoading: false,
    openEditFormState: false,
    error: null,
    message: null,
    editMessage: null,
    editError: null,
    deleteButtonLoading: false,
    deleteFlag: false,
    showPopUpModal: false,
    passwordButtonLoading: false,
    passwordMessage: null,
    passwordError: null,
    orderDataResult: [],
    retryOrderLoading: false,
    retryOrderError: null,
    retryOrderMessage: null,
    orderLoading:false,
}

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.RETRY_ORDER:
            return {
                ...state,
                retryOrderLoading: true,
                retryOrderError: null,
                retryOrderMessage: null,

            };
        case actions.RETRY_ORDER_SUCCESS:
            return {
                ...state,
                retryOrderLoading: false,
                retryOrderError: null,
                retryOrderMessage: action.payload.message,
            };
        case actions.RETRY_ORDER_FAILED:
            return {
                ...state,
                retryOrderLoading: false,
                retryOrderError: action.payload.error,
                retryOrderMessage: null,
            };


        case actions.GET_POFILE_DATA:
            return {
                ...state,
                loading: true,
                openEditFormState: false,
                editMessage: null,
                editError: null,
            };
        case actions.GET_POFILE_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                profileDataResult: action.profileDataResult,
                profileError: null
            };
        case actions.GET_POFILE_DATA_FAILED:
            return {
                ...state,
                loading: false,
                profileError: action.profileError,
                profileMessage: null,
            };
        case actions.OPEN_EDIT_FORM:
            return {
                ...state,
                message: null,
                error: null,
                openEditFormState: true,
                editMessage: null,
                editError: null,
            };

        case actions.CLOSE_EDIT_FORM:
            return {
                ...state,
                message: null,
                error: null,
                openEditFormState: false
            };

        case actions.EDIT_PROFILE_LIST:
            return {
                ...state,
                buttonLoading: true,
                editError: null,
                editMessage: null,
                openEditFormState: true

            };
        case actions.EDIT_PROFILE_LIST_SUCCESS:
            // console.log("action", action.payload.message)
            return {
                ...state,
                editError: null,
                openEditFormState: true,
                editMessage: action.payload.message,
                buttonLoading: false,
            };
        case actions.EDIT_PROFILE_LIST_FAILED:
            return {
                ...state,
                loading: false,
                editMessage: null,
                editError: action.payload.error,
                openEditFormState: true,
                buttonLoading: false,
            };

        case actions.DELETE_CUSTOMER_ACCOUNT:
            return {
                ...state,
                deleteButtonLoading: true,
                deleteFlag: false,
            };
        case actions.DELETE_CUSTOMER_ACCOUNT_SUCCESS:
            return {
                ...state,
                deleteButtonLoading: false,
                deleteFlag: true,
            };
        case actions.DELETE_CUSTOMER_ACCOUNT_FAILED:
            return {
                ...state,
                deleteButtonLoading: false,
                error: action.payload.error,
                deleteFlag: false,
            };

        case actions.CHANGE_CUSTOMER_PASSWORD:
            return {
                ...state,
                passwordButtonLoading: true,
                passwordMessage: null,
                passwordError: null,
            };

        case actions.CHANGE_CUSTOMER_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordButtonLoading: false,
                passwordMessage: action.payload.message
            };
        case actions.CHANGE_CUSTOMER_PASSWORD_FAILED:
            return {
                ...state,
                passwordButtonLoading: false,
                passwordMessage: null,
                passwordError: action.payload.error
            };

        case actions.RESET_MESSAGE:
            return {
                ...state,
                passwordMessage: null,
                passwordError: null,
                message: null,
                error: null,
            };

        case actions.GET_ORDER_DATA:
            return {
                ...state,
                orderLoading: true,
                profileError: null
            };
        case actions.GET_ORDER_DATA_SUCCESS:
            return {
                ...state,
                orderLoading: false,
                orderDataResult: action.orderDataResult,
            };
        case actions.GET_ORDER_DATA_FAILED:
            return {
                ...state,
                orderLoading: false,
                profileError: action.profileError,
                profileMessage: null,
            };

        default:
            return state;
    }
};