import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthActions from './../../redux/auth/action';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/utils/Alert';
import Button from '../../components/utils/Button';
import GoogleAuth from '../../components/layout/GoogleAuth';
import MohImage from './../../assets/images/moh-logo-blue-(1).png'
import LoginCartoon from './../../assets/images/login-3dimg.png'
import UtilityActions from '../../redux/utility/action';
import FacebookAuth from '../../components/layout/FacebookAuth';


const { closeLoginModal } = UtilityActions;

const { signIn, authReset } = AuthActions;

const LoginModal = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [passwordShow, setPasswordShow] = useState(false);
    const { signInToken, signInLoading, signInMessage, signInError } = useSelector((state) => state.auth);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');


    const [loginData, setLoginData] = useState({
        email: '',
        password: ''
    })

    useEffect(() => {
        if (signInError !== null && signInError !== undefined) {
            displayAlert(signInError, 'alert-danger');
        }
        if (signInMessage !== null && signInMessage !== undefined) {
            displayAlert(signInMessage, 'alert-success');
        }
    }, [signInError, signInMessage]);

    useEffect(() => {
        if (signInMessage) {
            localStorage.setItem("token", JSON.stringify(signInToken))
            window.location.reload();;
        }
    }, [signInMessage])


    const handleEmailChange = (e) => {
        const value = e.target.value
        setLoginData(pre => ({
            ...pre,
            email: value
        }))
    }

    const handlePasswordChange = (e) => {
        const value = e.target.value
        setLoginData(pre => ({
            ...pre,
            password: value
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(signIn(loginData))
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(authReset());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(authReset());
    }

    const handleCloseModal = () => {
        dispatch(closeLoginModal())
    }

    return (
        <div tabindex="-1" className='flex justify-center bg-[#00000073] p-4 class="hidden overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full"'>
            <div className='relative p-4 w-full max-w-[33rem] max-h-full'>
                <div className="relative bg-white rounded-lg shadow">
                    <div class="flex items-center justify-between xl:py-[12px] xl:px-[24px] md:p-5 border-b rounded-t">
                        <h3 class="text-xl font-semibold text-gray-900">
                            Login to your account
                        </h3>
                        <button onClick={handleCloseModal} type="button" class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center" data-modal-hide="default-modal">
                            <svg class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                            </svg>
                            <span class="sr-only">Close modal</span>
                        </button>
                    </div>

                    <div className="mt-5 mb-5 xl:max-w-[28rem] lg:max-w-[28rem] sm:mx-auto sm:w-full sm:max-w-sm pb-[2px]">
                       
                        <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-[0px]">
                            {showAlert && <div className='mb-[15px]'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
                        </div>
                        <form
                            className="space-y-6"
                            action="#" method="POST"
                        >
                            <div >
                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                    Email address
                                </label>
                                <div className="mt-2">
                                    <input
                                        onChange={(e) => handleEmailChange(e)}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        value={loginData.email}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                                    />
                                </div>
                            </div>

                            <div className='!mt-[12px]'>
                                <div className="flex items-center justify-between">

                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                        Password
                                    </label>
                                    <div className="text-sm">
                                        <a href="/forget-password" className="font-semibold text-[#223b8b] hover:text-[#223b8b]">
                                            Forgot your password?
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-2 relative">
                                    <input
                                        onChange={(e) => handlePasswordChange(e)}
                                        id="password"
                                        name="password"
                                        type={passwordShow ? 'text' : 'password'}
                                        autoComplete="current-password"
                                        value={loginData.password}
                                        required
                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                                    />
                                    {passwordShow ? <div onClick={() => setPasswordShow((pre) => !pre)} className="signUpEyeIcon cursor-pointer"><i class="ri-eye-line"></i></div> : <div className="signUpEyeIcon cursor-pointer" onClick={() => setPasswordShow((pre) => !pre)}><i class="ri-eye-off-line"></i></div>}

                                </div>
                            </div>

                            <div>
                                <Button title={'Sign In'} onClick={handleSubmit} loading={signInLoading} buttonClass="flex w-full justify-center rounded-md bg-[#223b8b] px-3 py-3 text-[18px] font-semibold leading-6 text-white shadow-sm hover:bg-[#223b8b] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" buttonType='button' />
                            </div>
                        </form>

                        {/* <div className="relative mb-6 mt-2 text-center">
                            <span>OR</span>
                            <hr className='mt-2' />
                        </div> */}

                        <div className="flex items-center justify-center mt-3 gap-4 mb-3 text-center">
                            <GoogleAuth />
                            <FacebookAuth />
                        </div>

                        <p className="mt-2 mb-4 text-center text-sm text-gray-500">
                            Don't have an account?
                            <NavLink to="/sign-up" className="font-semibold leading-6 text-[#223b8b] hover:text-[#223b8b]">
                                Sign Up
                            </NavLink>
                        </p>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default LoginModal
