const actions = {
    GET_UTILITY_COMMISSION: 'GET_UTILITY_COMMISSION',
    GET_UTILITY_COMMISSION_SUCCESS: 'GET_UTILITY_COMMISSION_SUCCESS',
    GET_UTILITY_COMMISSION_FAILED: 'GET_UTILITY_COMMISSION_FAILED',
    SEARCH_FOR_UTILITY_DETAILS: "SEARCH_FOR_UTILITY_DETAILS",
    SEARCH_FOR_UTILITY_DETAILS_SUCCESS: "SEARCH_FOR_UTILITY_DETAILS_SUCCESS",
    SEARCH_FOR_UTILITY_DETAILS_FAILED: "SEARCH_FOR_UTILITY_DETAILS_FAILED",
    FORM_RESET: 'FORM_RESET',
    CREATE_UTILITY_ORDER: "CREATE_UTILITY_ORDER",
    CREATE_UTILITY_ORDER_SUCCESS: "CREATE_UTILITY_ORDER_SUCCESS",
    CREATE_UTILITY_ORDER_FAILED: "CREATE_UTILITY_ORDER_FAILED",
    FAILED_UTILITY_ORDER: "FAILED_UTILITY_ORDER",
    FAILED_UTILITY_ORDER_SUCCESS: "FAILED_UTILITY_ORDER_SUCCESS",
    FAILED_UTILITY_ORDER_FAILED: "FAILED_UTILITY_ORDER_FAILED",
    OPEN_LOGIN_MODAL: "OPEN_LOGIN_MODAL",
    CLOSE_LOGIN_MODAL: "CLOSE_LOGIN_MODAL",
    PROCESS_UTILITY_ORDER: "PROCESS_UTILITY_ORDER",
    PROCESS_UTILITY_ORDER_SUCCESS: "PROCESS_UTILITY_ORDER_SUCCESS",
    PROCESS_UTILITY_ORDER_FAILED: "PROCESS_UTILITY_ORDER_FAILED",
    COMPLETE_UTILITY_ORDER: "COMPLETE_UTILITY_ORDER",
    COMPLETE_UTILITY_ORDER_SUCCESS: "COMPLETE_UTILITY_ORDER_SUCCESS",
    COMPLETE_UTILITY_ORDER_FAILED: "COMPLETE_UTILITY_ORDER_FAILED",

    completeUtilityOrder: (data, utilityID, orderID) => ({
        type: actions.COMPLETE_UTILITY_ORDER,
        payload: { data, utilityID, orderID }
    }),
    completeUtilityOrderSuccess: (message) => ({
        type: actions.COMPLETE_UTILITY_ORDER_SUCCESS,
        payload: { message }
    }),
    completeUtilityOrderFailed: (error) => ({
        type: actions.COMPLETE_UTILITY_ORDER_FAILED,
        payload: { error }
    }),

    processUtilityOrder: (data) => ({
        type: actions.PROCESS_UTILITY_ORDER,
        payload: { data }
    }),
    processUtilityOrderSuccess: (message, data) => ({
        type: actions.PROCESS_UTILITY_ORDER_SUCCESS,
        payload: { message, data }
    }),
    processUtilityOrderFailed: (error) => ({
        type: actions.PROCESS_UTILITY_ORDER_FAILED,
        payload: { error }
    }),

    failedUtilityOrder: (data) => ({
        type: actions.FAILED_UTILITY_ORDER,
        payload: { data }
    }),
    failedUtilityOrderSuccess: (message) => ({
        type: actions.FAILED_UTILITY_ORDER_SUCCESS,
        payload: { message }
    }),
    failedUtilityOrderFailed: (error) => ({
        type: actions.FAILED_UTILITY_ORDER_FAILED,
        payload: { error }
    }),

    createUtilityOrder: (utilityId, data) => ({
        type: actions.CREATE_UTILITY_ORDER,
        payload: { utilityId, data }
    }),
    createUtilityOrderSuccess: (message, data) => ({
        type: actions.CREATE_UTILITY_ORDER_SUCCESS,
        payload: { message, data }
    }),
    createUtilityOrderFailed: (error) => ({
        type: actions.CREATE_UTILITY_ORDER_FAILED,
        payload: { error }
    }),

    getUtilityCommission: (uID) => ({
        type: actions.GET_UTILITY_COMMISSION,
        payload: { uID }
    }),
    getUtilityCommissionSuccess: (data) => ({
        type: actions.GET_UTILITY_COMMISSION_SUCCESS,
        payload: { data }
    }),
    getUtilityCommissionFailed: (error) => ({
        type: actions.GET_UTILITY_COMMISSION_FAILED,
        payload: { error }
    }),

    searchForUtilityDetails: (utilityId, data) => ({
        type: actions.SEARCH_FOR_UTILITY_DETAILS,
        payload: { utilityId, data }

    }),
    searchForUtilityDetailsSuccess: (result) => ({
        type: actions.SEARCH_FOR_UTILITY_DETAILS_SUCCESS,
        payload: { result }
    }),
    searchForUtilityDetailsFailed: (error) => ({
        type: actions.SEARCH_FOR_UTILITY_DETAILS_FAILED,
        payload: { error }
    }),
    formReset: () => ({
        type: actions.FORM_RESET,
    }),

    openLoginModal: () => ({
        type: actions.OPEN_LOGIN_MODAL,
    }),
    closeLoginModal: () => ({
        type: actions.CLOSE_LOGIN_MODAL,
    }),


};
export default actions;