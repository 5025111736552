import { useEffect, useState } from 'react'
import rechargeSvg from './../../assets/images/clouds.png'
import { useDispatch, useSelector } from 'react-redux'
import HomeActions from '../../redux/home/action';
import { useNavigate } from "react-router-dom";

const { getCountriesWhoHasNetwork, getNetworksByCountryID, setFormData } = HomeActions;

const HowToRecharge = () => {
    const dispatch = useDispatch();
    const { getCountriesWhoHasNetworkList, formObject } = useSelector((state) => state.home);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getCountriesWhoHasNetwork());
        dispatch(getNetworksByCountryID());
    }, []);

    const handleCountryChange = (e) => {
        let countryId = e.target.value;
        dispatch(setFormData('countryId', countryId));
        dispatch(getNetworksByCountryID(countryId));
        window.scrollTo(10, 10);
        navigate("/recharge");
    };
    return (
        <>
            <section className="wrapper !bg-[#8ca0e12e]">
                <div className="container mx-auto">
                    <div className='py-14' >
                        <h1 className="pt-32 xl:text-[2.5rem] text-[calc(1.375rem_+_1.5vw)] !leading-[1.15] font-bold  xl:!mr-5 xl:mt-[-2.5rem] lg:mt-[-2.5rem] text-center" data-cue="slideInDown" data-group="page-title" data-delay="900" data-show="true" >How to <br className="hidden md:block xl:hidden lg:hidden" /><span className="text-[#1d89ca] ">Recharge</span></h1>
                    </div>
                </div>

                <figure className="m-0 p-0"><img className="w-full max-w-full !h-auto" src={rechargeSvg} alt="image" /></figure>
            </section>
            <section className='pb-12'>
                <div className="container mx-auto">
                    <div className=' mx-auto w-3/4 bg- from-white rounded-md'>
                        <h4 className='text-center mb-8 text-3xl font-bold tracking-tight text-gray-900'>Recharge a mobile phone in 3 easy steps:</h4>
                        <div className="grid grid-cols-3 gap-16">


                            <div className="max-w-sm p-6 text-center relative bg-white border border-gray-200 rounded-lg shadow">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1d89ca" className="w-12 h-12 inline  mb-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
                                </svg>
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Recharge Details</h5>
                                <p className="mb-3 font-normal text-gray-900">Choose which country and network you want to recharge.</p>
                                <div className="top-12 right-0 flex items-center justify-center h-24 lg:-mr-12 lg:absolute"><svg className="w-8 text-[#1d89ca] transform rotate-90 lg:rotate-0" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24"><line fill="none" strokeMiterlimit="10" x1="2" y1="12" x2="22" y2="12"></line><polyline fill="none" strokeMiterlimit="10" points="15,5 22,12 15,19 "></polyline></svg></div>
                            </div>

                            <div className="max-w-sm p-6 relative bg-white border text-center border-gray-200 rounded-lg shadow">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1d89ca" className="w-12 h-12 inline  mb-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                                </svg>


                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Proceed to Payment</h5>

                                <p className="mb-3 font-normal text-gray-900">Pay with your PayPal, credit or debit card. It doesn't matter what you choose.</p>
                                <div className="top-12 right-0 flex items-center justify-center h-24 lg:-mr-12 lg:absolute"><svg className="w-8 text-[#1d89ca] transform rotate-90 lg:rotate-0" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" viewBox="0 0 24 24"><line fill="none" strokeMiterlimit="10" x1="2" y1="12" x2="22" y2="12"></line><polyline fill="none" strokeMiterlimit="10" points="15,5 22,12 15,19 "></polyline></svg></div>
                            </div>



                            <div className="max-w-sm p-6 bg-white text-center border border-gray-200 rounded-lg shadow">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#1d89ca" className="w-12 h-12 inline  mb-3">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                                </svg>
                                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">Phone is Recharged</h5>
                                <p className="mb-3 font-normal text-gray-900">Your recharge is sent almost instantly and the recipient can enjoy the credit.</p>
                            </div>
                        </div>
                    </div>
                    <div className="xl:flex xl:items-center xl:justify-center lg:flex lg:items-center lg:justify-center   md:flex md:items-center md:justify-center sm:flex sm:items-center sm:justify-center flex items-center justify-center py-8 mt-5">
                        <div className="form-group mb-0 xl:w-1/3 lg:w-1/2 md:w-1/2 sm:w-1/2 w-full xl:mx-10">
                            <div className="select-has-flag position-relative w-full">
                                <select id="countries" onChange={(e) => handleCountryChange(e)}
                                    className="bg-gray-50 border py-4 text-[18px] font-bold border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-[#223b8b] focus:border-[#223b8b] block w-full p-2.5" >
                                    <option value=""> Select Country </option>
                                    {getCountriesWhoHasNetworkList && getCountriesWhoHasNetworkList.length > 0 && getCountriesWhoHasNetworkList.map((item) => (<option key={`country${item.ID}`} value={item.ID} > {item.CountryName} </option>))}
                                </select>

                            </div></div></div>
                </div>
            </section>
        </>
    )
}

export default HowToRecharge
