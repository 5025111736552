import { BrowserRouter, Routes, Route, Outlet, Navigate, } from "react-router-dom";
import { PaymentRoute, UnAuthenticatedRoute, AuthenticatedRoute } from "./helper/routeProtect";
import HomePage from "./pages/HomePage/HomePage";
import AboutUs from "./pages/AboutUs/AboutUs";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import Login from "./pages/LogIn/Login";
import Signup from "./pages/SignUp/Signup";
import Recharge from "./pages/Recharge/Recharge";
import HowToRecharge from "./pages/HowToRecharge/HowToRecharge";
import ContactUs from "./pages/ContactUs/ContactUs";
import Profile from "./pages/Profile/ProfileMainPage";
import PaymentFailed from "./pages/Recharge/PaymentFailed";
import PaymentSuccess from "./pages/Recharge/PaymentSuccess";
import NotFound from "./components/utils/NotFound";
import ForgetPassword from "./pages/ForgetPassword/ForgetPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import PostpaidElectricityBill from "./pages/PostpaidElectricityBill/PostPaidElectricityBill";
import PrepaidElectricityBill from './pages/PrepaidElectricityBill/PrepaidElectricityBill';
import WaterBill from "./pages/WaterBill/WaterBill";
import Rapido from './pages/Rapido/Rapido';
import UtilityPaymentFailed from "./pages/UtilityPayments/UtilityPaymentFailed";
import UtilityPaymentSuccess from "./pages/UtilityPayments/UtilityPaymentSuccess";

function App() {

  const Layout = () => {
    return (
      <>
        <Header />
        <Outlet />
        <Footer />
      </>
    )
  }

  return <>
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<UnAuthenticatedRoute><Login /></UnAuthenticatedRoute>} />
        <Route path="/sign-up" element={<UnAuthenticatedRoute><Signup /></UnAuthenticatedRoute>} />
        <Route path="/forget-password" element={<UnAuthenticatedRoute><ForgetPassword /></UnAuthenticatedRoute>} />
        <Route path="/reset-password" element={<UnAuthenticatedRoute><ResetPassword /></UnAuthenticatedRoute>} />
        <Route path="/verify-email" element={<UnAuthenticatedRoute><VerifyEmail /></UnAuthenticatedRoute>} />

        <Route path="/" element={<Layout />} >
          <Route path="/" element={<HomePage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/recharge" element={<Recharge />} />
          <Route path="/how-to-recharge" element={<HowToRecharge />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/profile" element={<AuthenticatedRoute><Profile /></AuthenticatedRoute>} />
          <Route path="/postpaid-electricity/:id" element={<PostpaidElectricityBill />} />
          <Route path="/prepaid-electricity/:id" element={<PrepaidElectricityBill />} />
          <Route path="/water-bill/:id" element={<WaterBill />} />
          <Route path="/rapido/:id" element={<Rapido />} />
          <Route path="/upayment-failed" element={<PaymentRoute><UtilityPaymentFailed /></PaymentRoute>} />
          <Route path="/upayment-success" element={<PaymentRoute><UtilityPaymentSuccess /></PaymentRoute>} />
          <Route path="/payment-failed" element={<PaymentRoute><PaymentFailed /></PaymentRoute>} />
          <Route path="/payment-success" element={<PaymentRoute><PaymentSuccess /></PaymentRoute>} />

        </Route>
      </Routes>
    </BrowserRouter>
  </>;
}

export default App;