import { put, call, all, takeEvery } from 'redux-saga/effects';
import actions from './actions';
import { getList, addData } from '../../helper/fackBackend_Helper'

function* getProfileDataResponse() {
    try {
        const response = yield call(getList, '/profile');
        if (response.response.Status) {
            yield put(actions.getProfileDataSuccess(response.response.Data));
        } else {
            yield put(actions.getProfileDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getProfileDataFailed(error));
    }
}

function* editProfileListDataResponse({ payload: { Id, data } }) {
    try {
        const response = yield call(addData, '/UpdateProfile/' + Id, data);
        if (response.response.Status) {
            yield put(actions.editProfileListSuccess(response.response.Message));
        } else {
            yield put(actions.editProfileListFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.editProfileListFailed(error));
    }
}

function* deleteCustomerAccountResponse({ payload: { id } }) {
    try {
        const response = yield call(addData, '/deleteProfile/' + id);
        if (response.response.Status) {
            yield put(actions.deleteCustomerAccountSuccess(response.response.Message));
        } else {
            yield put(actions.deleteCustomerAccountFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.deleteCustomerAccountFailed(error));
    }
}

function* changeCustomerPasswordResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/changeCustomerPassword', data);
        if (response.response.Status) {
            yield put(actions.changeCustomerPasswordSuccess(response.response.Message));
        } else {
            yield put(actions.changeCustomerPasswordFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.changeCustomerPasswordFailed(error));
    }
}

function* getOrderDataResponse() {
    try {
        const response = yield call(getList, '/orders');
        if (response.response.Status) {
            yield put(actions.getOrderDataSuccess(response.response.Data));
        } else {
            yield put(actions.getOrderDataFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getOrderDataFailed(error));
    }
}

function* retryOrderResponse({ payload: { id } }) {
    try {
        const response = yield call(getList, '/retry-order/' + id);
        if (response.Status) {
            yield put(actions.getOrderData());
            yield put(actions.retryOrderSuccess(response.Message));
        } else {
            yield put(actions.getOrderData());
            yield put(actions.retryOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.retryOrderFailed(error.message));
    }
}

export default function* rootSaga() {
    yield all([takeEvery(actions.GET_POFILE_DATA, getProfileDataResponse)]);
    yield all([takeEvery(actions.EDIT_PROFILE_LIST, editProfileListDataResponse)]);
    yield all([takeEvery(actions.DELETE_CUSTOMER_ACCOUNT, deleteCustomerAccountResponse)]);
    yield all([takeEvery(actions.CHANGE_CUSTOMER_PASSWORD, changeCustomerPasswordResponse)]);
    yield all([takeEvery(actions.GET_ORDER_DATA, getOrderDataResponse)]);
    yield all([takeEvery(actions.RETRY_ORDER, retryOrderResponse)]);

}