const actions = {
  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILED: 'SIGN_IN_FAILED',
  AUTH_RESET: 'AUTH_RESET',
  SIGN_UP: 'SIGN_UP',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAILED: 'SIGN_UP_FAILED',
  GOOGLE_AUTH: 'GOOGLE_AUTH',
  GOOGLE_AUTH_SUCCESS: 'GOOGLE_AUTH_SUCCESS',
  GOOGLE_AUTH_FAILED: 'GOOGLE_AUTH_FAILED',
  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILED: 'SIGN_OUT_FAILED',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILED: 'FORGOT_PASSWORD_FAILED',
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILED: "VERIFY_EMAIL_FAILED",
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'RESET_PASSWORD_FAILED',

  signIn: (data) => ({
    type: actions.SIGN_IN,
    data,
  }),
  signInSuccess: (token) => ({
    type: actions.SIGN_IN_SUCCESS,
    token,
  }),
  signInFailed: (error) => ({
    type: actions.SIGN_IN_FAILED,
    error,
  }),

  userSignUp: (data) => ({
    type: actions.SIGN_UP,
    data,
  }),
  signUpSuccess: (message) => ({
    type: actions.SIGN_UP_SUCCESS,
    message,
  }),
  signUpFailed: (error) => ({
    type: actions.SIGN_UP_FAILED,
    error,
  }),

  googleAuth: (data) => ({
    type: actions.GOOGLE_AUTH,
    data,
  }),
  googleAuthSuccess: (token) => ({
    type: actions.GOOGLE_AUTH_SUCCESS,
    token,
  }),
  googleAuthFailed: (error) => ({
    type: actions.GOOGLE_AUTH_FAILED,
    error,
  }),

  authReset: () => ({
    type: actions.AUTH_RESET,
  }),

  signout: () => ({
    type: actions.SIGN_OUT,
  }),
  signoutSuccess: () => ({
    type: actions.SIGN_OUT_SUCCESS,
  }),
  signoutFailed: (error) => ({
    type: actions.SIGN_OUT_FAILED,
    payload: { error },
  }),

  forgotPassword: (data) => ({
    type: actions.FORGOT_PASSWORD,
    data,
  }),
  forgotPasswordSuccess: (message) => ({
    type: actions.FORGOT_PASSWORD_SUCCESS,
    message,
  }),
  forgotPasswordFailed: (error) => ({
    type: actions.FORGOT_PASSWORD_FAILED,
    error,
  }),

  verifyEmail: (data) => ({
    type: actions.VERIFY_EMAIL,
    data,
  }),
  verifyEmailSuccess: (message) => ({
    type: actions.VERIFY_EMAIL_SUCCESS,
    message,
  }),
  verifyEmailFailed: (error) => ({
    type: actions.VERIFY_EMAIL_FAILED,
    error,
  }),
  resetPassword: (data) => ({
    type: actions.RESET_PASSWORD,
    data,
  }),
  resetPasswordSuccess: (message) => ({
    type: actions.RESET_PASSWORD_SUCCESS,
    message,
  }),
  resetPasswordFailed: (error) => ({
    type: actions.RESET_PASSWORD_FAILED,
    error,
  }),

};
export default actions;