import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import rechargeSvg from './../../assets/images/cloud-bg.png';
import Button from '../../components/utils/Button';
import { isAuthenticated } from '../../helper/commonFunction';
import UtilityActions from '../../redux/utility/action'
import HomeActions from '../../redux/home/action';
import Loader from '../../components/utils/Loader';
import LoginModal from '../../components/utils/LoginModal';
import { makeUtilityPayment } from '../../helper/stripe';
import Alert from '../../components/utils/Alert';
import moment from "moment";
import NoImage from '../../assets/images/no_image.png';




const BASE_URL = process.env.REACT_APP_BASEURL;

const { verifyUtilityID } = HomeActions;
const checkField = (val) => (val != '' && val != null && val != undefined);
const { getUtilityCommission, formReset, searchForUtilityDetails, createUtilityOrder, openLoginModal } = UtilityActions;

const WaterBill = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { searchUtilityDetailsData, searchUtilityDetailsLoading, searchUtilityDetailsError, getUtilityCommissionList, createUtilityOrderLoading, showLoginModal, createUtilityOrderData } = useSelector((state) => state.utility)
    const { getNetworkByCountryIDMessage, getNetworkByCountryIDError, getNetworkByCountryIDLoading,UtilitylogoUrl } = useSelector((state) => state.home);
    const [billNumber, setBillNumber] = useState('');
    const [email, setEmail] = useState('');
    const [showSearchButton, setShowSearchButton] = useState(false);
    const [totalAmount, setTotalAmount] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [showProceedData, setShowProceedData] = useState(false);
    const [showBackIcon, setShowBackIcon] = useState(false);
    const [newSearchUtilityDetailsData, setNewSearchUtilityDetailsData] = useState([]);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');


    useEffect(() => {
        dispatch(verifyUtilityID(id))
        dispatch(getUtilityCommission(id))
    }, [id])

    useEffect(() => {
        if (getNetworkByCountryIDError) {
            window.location.href = `/`;
        }
    }, [getNetworkByCountryIDMessage, getNetworkByCountryIDError])

    // useEffect(() => {
    //     if (newSearchUtilityDetailsData && newSearchUtilityDetailsData.length > 0) {
    //         let netAmount = newSearchUtilityDetailsData[0].total
    //         setTotalAmount(netAmount)
    //     }
    // }, [newSearchUtilityDetailsData])

    useEffect(() => {
        if (searchUtilityDetailsData && searchUtilityDetailsData.length == 1) {
            setNewSearchUtilityDetailsData(searchUtilityDetailsData)
            let netAmount = searchUtilityDetailsData[0].total
            setTotalAmount(netAmount)
            setShowProceedData(true)
            setShowBackIcon(false)
        }
    }, [searchUtilityDetailsData])

    useEffect(() => {
        if (createUtilityOrderData.ID && createUtilityOrderData.OrderNumber) {
            localStorage.setItem('payment', 1);
            const products = [
                {
                    name: createUtilityOrderData.UtilityName,
                    price: createUtilityOrderData.AmountPaid,
                    // image: `${BASE_URL}/images/network/${createUtilityOrderData.NetworkLogoURL}`,
                    quantity: 1
                }
            ]
            makeUtilityPayment(products, createUtilityOrderData.PaymentTxnID, id);
        }
    }, [createUtilityOrderData]);

    useEffect(() => {
        if (searchUtilityDetailsError !== null && searchUtilityDetailsError !== undefined) {
            displayAlert(searchUtilityDetailsError, 'alert-danger');
        }
    }, [searchUtilityDetailsError]);

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(formReset());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(formReset());
    }


    const allFieldValid = useMemo(() => {
        if (checkField(billNumber) && checkField(totalAmount) && checkField(mobileNumber)) {
            return true;
        } else {
            return false;
        }
    }, [billNumber, totalAmount, mobileNumber]);

    const handleChangeBillNumber = (e) => {
        let value = e.target.value
        if (value) {
            setShowSearchButton(true)
            setBillNumber(value)
        }
        else {
            setShowSearchButton(false)
            dispatch(formReset())
            setEmail('')
            checkField('')
        }
    }

    const handleSearch = (e) => {
        e.preventDefault();
        setNewSearchUtilityDetailsData([])
        setShowProceedData(false)
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        const data = {
            consumerNumber: billNumber,
            endPoint: "wizall/seneau/searchBill"

        }
        if (data.billNumber != '' || data.billNumber != 'undefined' || data.billNumber != null) {
            if (isAuthenticated()) {
                dispatch(searchForUtilityDetails(id, data));
            } else {
                dispatch(openLoginModal())
            }
        }
    };

    const handleMobileNumberChange = (e) => {
        let value = e.target.value;

        if (value.length <= 10) {
            setMobileNumber(value);
        }
    }

    // const handleEmailChange = (e) => {
    //     const email = e.target.value;
    //     const pattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
    //     const isValidEmail = pattern.test(email);
    //     if (isValidEmail) {
    //         setEmail(email)
    //     } else {
    //         setEmail('')
    //     }
    // };

    const onSubmit = (e) => {
        e.preventDefault();
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        const data = {
            ConsumerNumber: billNumber,
            SenderMobile: mobileNumber,
            InvoiceNumber: newSearchUtilityDetailsData[0].reference_facture,
            UtilityID: id,
            FeePaid: searchUtilityDetailsData[0].frais,
            AmountPaid: totalAmount,
        }
        dispatch(createUtilityOrder(id, data))
    };
    const handlePay = (item) => {
        setShowProceedData(true)
        setShowBackIcon(true)
        setNewSearchUtilityDetailsData([item])
        setTotalAmount(item.total)
    }
    const handleBack = () => {
        setShowProceedData(false)
        setNewSearchUtilityDetailsData([])
        setMobileNumber('')
    }


    return (
        <>
        
            {getNetworkByCountryIDLoading ? <Loader /> : <div className='maindiv-elrctric'>
                <section className="wrapper !bg-[#8ca0e12e]">
                    <div className="container mx-auto">
                        <div className='py-14' >
                            <h1 className="pt-32 xl:text-[2.5rem] text-[calc(1.375rem_+_1.5vw)] !leading-[1.15] font-bold  xl:!mr-5 xl:mt-[-2.5rem] lg:mt-[-2.5rem] text-center" data-cue="slideInDown" data-group="page-title" data-delay="900" data-show="true" >
                            {/* <img className='postPaidElectric'  src={UtilitylogoUrl ? `${BASE_URL}/images/utilities/${UtilitylogoUrl}` : NoImage} alt={''} width={90} height={90}/> */}
                             Water <br className="hidden md:block xl:hidden lg:hidden" /><span className="text-[#1d89ca] ">Bill</span></h1>
                        </div>
                    </div>
                    <figure className="m-0 p-0"><img className="w-full max-w-full !h-auto" src={rechargeSvg} alt="image" /></figure>
                </section>
                <section id="snippet-2" className="wrapper !bg-[#f5f7fa] border-b-[rgba(164,174,198,0.2)]  px-32">
                    <form onSubmit={onSubmit}>
                        <div className='w-[500px] mx-auto p-2'>
                            <div className="bg-white shadow-md rounded px-6 pb-4 payrechar-d">
                                <div className='flex justify-between mb-4'>
                                    <div className='flex gap-2'> 
                                        {showProceedData && showBackIcon ? <div onClick={handleBack} className='flex justify-start items-center mt-[0.3rem] cursor-pointer h-[20px]'><i class="ri-arrow-left-line text-xl"></i></div> : ''}
                                    <h2 className="text-start text-lg font-[600] mb-4">
                                        {/* Please enter the information for payment */}
                                        Enter payment details
                                        </h2></div>
                                    <div> <img className='postPaidElectric' style={{margin:'-8px 0 0px 0' }} src={UtilitylogoUrl ? `${BASE_URL}/images/utilities/${UtilitylogoUrl}` : NoImage} alt={''} width={90} height={90}/></div>
                                   
                                </div>
                                {showAlert && <div className='flex gap-1 items-center mb-4' style={{ color: '#d63031' }}>
                                        <i class="ri-close-circle-line "></i><span style={{fontSize:'15px'}}>{alertText}</span>
                                    </div>}

                                <div className="flex items-center justify-between gap-3">
                                    <div className="w-full">
                                        
                                        <div className="relative w-full input-container">
                                            <input
                                                type="text"
                                                placeholder=""
                                                className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none input-field"
                                                onChange={(e) => handleChangeBillNumber(e)}
                                                maxLength={15}
                                            />
                                              <label for="input" class="label">Consumer Reference Number</label>
                                              <div class="underline"></div>
                                        </div>
                                    </div>
                                    {showSearchButton ? (
                                        <div className="flex justify-start mt-0 w-[5rem] ">
                                            <Button
                                                loading={searchUtilityDetailsLoading}
                                                title={''}
                                                icon={searchUtilityDetailsLoading ? '' : < i class="ri-search-line"></i>}
                                                buttonClass={`h-[35px] w-full pt-[5px] flex align-center justify-center relative font-medium text-white transition duration-300 rounded-md bg-[#223b8be6] hover:bg-[#223b8b] ease  cursor-pointer`}
                                                buttonType={'button'}
                                                onClick={handleSearch}
                                            />
                                        </div>
                                    ) : null}
                                </div>


                                {searchUtilityDetailsData && searchUtilityDetailsData.length > 0 ? <div className='py-3'>
                                    <h1 className='font-bold text-xl '>Bill Details:</h1>
                                </div> : ''}
                                {searchUtilityDetailsData && searchUtilityDetailsData.length > 1 ? <div>
                                    <div className='flex gap-6'>
                                        <div className='flex w-full gap-2 items-center'> <span className='font-semibold'>Consumer Name:</span> <span className='text-sm'>{searchUtilityDetailsData[0].prenom} {searchUtilityDetailsData[0].nom}</span> </div>
                                    </div>
                                </div> : ''}

                                {!showProceedData && searchUtilityDetailsData && searchUtilityDetailsData.length > 1 && searchUtilityDetailsData.map(item => (
                                    <div className='waterDetails flex flex-col gap-4 rounded-sm w-full mb-3 mt-2'>
                                        <div className='flex gap-6'>
                                            <div className='flex w-1/2 justify-between'> <span className='font-semibold'>Payment Txn #:</span> <span className='text-sm'>{item.PAYMENT_TRANSACTION_NUMBER}</span> </div>
                                            <div className='flex w-1/2 justify-between'> <span className='font-semibold '>Invoice #:</span> <span className='text-sm'>{item.reference_facture}</span> </div>
                                        </div>
                                        <div className='flex gap-6'>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Due Date:</span> <span className='text-sm'>{moment(item.date_echeance).format(process.env.REACT_APP_DATE_FORMAT)}</span> </div>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Amount:</span> <span className='text-sm'><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.montant}</span> </div>
                                        </div>
                                        <div className='flex gap-6'>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Fee:</span> <span className='text-sm'><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.frais}</span> </div>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Total Amount:</span> <span ><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.total}</span> </div>
                                        </div>

                                        {item.statuspayment == false ? <div className='flex gap-6 '>
                                            <div className='flex w-full justify-end mr-2'>
                                                <Button
                                                    title={'Pay'}
                                                    loading={false}
                                                    buttonClass={` flex w-[60px] h-[28px] mb-[4px] align-center justify-center font-medium text-white transition duration-300 rounded-md bg-[#223b8be6] hover:bg-[#223b8b]  cursor-pointer`}
                                                    buttonType={'button'}
                                                    onClick={() => handlePay(item)}
                                                />
                                            </div>
                                        </div> : ''}
                                    </div>
                                ))}


                                {showProceedData && newSearchUtilityDetailsData && newSearchUtilityDetailsData.length > 0 && newSearchUtilityDetailsData.map(item => (
                                    <div className='flex flex-col gap-4 rounded-sm w-full mb-3 mt-2'>
                                        <div className='flex gap-6'>
                                            <div className='flex w-1/2 justify-between'> <span className='font-semibold'>Payment Txn #:</span> <span className='text-sm'>{item.PAYMENT_TRANSACTION_NUMBER}</span> </div>
                                            <div className='flex w-1/2 justify-between'> <span className='font-semibold '>Invoice #:</span> <span className='text-sm'>{item.reference_facture}</span> </div>
                                        </div>
                                        <div className='flex gap-6'>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Due Date:</span> <span className='text-sm'>{moment(item.date_echeance).format(process.env.REACT_APP_DATE_FORMAT)}</span> </div>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Amount:</span> <span className='text-sm'><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.montant}</span> </div>
                                        </div>
                                        <div className='flex gap-6'>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Fee:</span> <span className='text-sm'><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.frais}</span> </div>
                                            <div className='flex w-1/2 justify-between'><span className='font-semibold'>Total Amount:</span> <span ><span className='font-semibold'>{getUtilityCommissionList && getUtilityCommissionList.length > 0 ? getUtilityCommissionList[0].CurrencyCode == '$' ? getUtilityCommissionList[0].CurrencyCode : getUtilityCommissionList[0].CurrencyCode.toUpperCase() : ''}</span> {item.total}</span> </div>
                                        </div>
                                        <div className='flex gap-6 '>

                                        </div>
                                    </div>
                                ))}

                                {showProceedData && newSearchUtilityDetailsData && newSearchUtilityDetailsData.length > 0 ? (
                                    <div className="flex flex-col">
                                        <h2 className="text-start pb-2 xl:text-md lg:text-md font-medium text-gray-900">Amount</h2>
                                        <div className="relative w-full">
                                            <input
                                                value={totalAmount}
                                                type='number'
                                                placeholder='Amount'
                                                className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none"
                                                disabled
                                            />
                                        </div>
                                        <div className="flex flex-col">
                                            <h2 className="block mb-2 text-md font-medium text-gray-900">Mobile Number</h2>
                                            <div className="relative w-full">
                                                <input
                                                    type='number'
                                                    value={mobileNumber}
                                                    placeholder='Mobile Number'
                                                    className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none"
                                                    onChange={(e) => handleMobileNumberChange(e)}
                                                    onWheel={(e) => e.target.blur()}
                                                    onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                                />
                                            </div>
                                        </div>

                                    </div>
                                ) : null}

                                {showProceedData ? <Button
                                    title={'Proceed'}
                                    loading={createUtilityOrderLoading}
                                    buttonClass={`mx-auto w-full mt-[20px] text-lg flex align-center justify-center relative font-medium text-white transition duration-300 rounded-md ${allFieldValid ? 'bg-[#223b8be6] hover:bg-[#223b8b]  cursor-pointer' : 'bg-[#95a5a6]  cursor-not-allowed'} ease btn-padd-n`}
                                    buttonType={'submit'}
                                    disable={!allFieldValid}
                                /> : ''}
                            </div>
                        </div>

                    </form>
                </section>

                {showLoginModal ? <LoginModal /> : ''}
            </div>}

        </>
    )
}

export default WaterBill