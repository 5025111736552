const actions = {
  GET_COUNTRY: 'GET_COUNTRY',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILED: 'GET_COUNTRY_FAILED',
  GET_COUNTRY_FOR_WEBSITE: 'GET_COUNTRY_FOR_WEBSITE',
  GET_COUNTRY_FOR_WEBSITE_SUCCESS: 'GET_COUNTRY_FOR_WEBSITE_SUCCESS',
  GET_COUNTRY_FOR_WEBSITE_FAILED: 'GET_COUNTRY_FOR_WEBSITE_FAILED',
  
  getCountry: () => ({
    type: actions.GET_COUNTRY,
  }),
  getCountrySuccess: (countryResult) => ({
    type: actions.GET_COUNTRY_SUCCESS,
    countryResult
  }),
  getCountryFailed: (countryError) => ({
    type: actions.GET_COUNTRY_FAILED,
    countryError
  }),
  getCountryForWebsite: () => ({
    type: actions.GET_COUNTRY_FOR_WEBSITE,
  }),
  getCountryForWebsiteSuccess: (countryResultWebsite) => ({
    type: actions.GET_COUNTRY_FOR_WEBSITE_SUCCESS,
    countryResultWebsite
  }),
  getCountryForWebsiteFailed: (countryError) => ({
    type: actions.GET_COUNTRY_FOR_WEBSITE_FAILED,
    countryError
  }),
};
export default actions;
