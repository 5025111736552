import actions from "./actions";

const initState = {
    countryResult: [],
    loading: false,
    countryError: false,
    countryResultWebsite: [],
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_COUNTRY:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_COUNTRY_SUCCESS:
            return {
                ...state,
                loading: false,
                countryError: false,
                countryResult: action.countryResult,
            };
        case actions.GET_COUNTRY_FAILED:
            return {
                ...state,
                loading: false,
                countryError: action.countryError,
            };
        case actions.GET_COUNTRY_FOR_WEBSITE:
            return {
                ...state,
                loading: true,
            };
        case actions.GET_COUNTRY_FOR_WEBSITE_SUCCESS:
            return {
                ...state,
                loading: false,
                countryError: false,
                countryResultWebsite: action.countryResultWebsite,
            };
        case actions.GET_COUNTRY_FOR_WEBSITE_FAILED:
            return {
                ...state,
                loading: false,
                countryError: action.countryError,
            };
        default:
            return state;
    }
}
