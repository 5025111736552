import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./action";
import { getList } from "../../helper/fackBackend_Helper";

function* getCountriesWhoHasNetworkResponse() {
    try {
        const response = yield call(getList, "/getContoriesWhoHasNetwork");
        if (response.Status) {
            yield put(actions.getCountriesWhoHasNetworkSuccess(response.Countries));
        } else {
            yield put(actions.getCountriesWhoHasNetworkFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getCountriesWhoHasNetworkFailed(error.Message));
    }
}

function* getNetworksByCountryIdResponse({ payload: { countryID } }) {
    try {
        const response = yield call(getList, "/getNetworksByCountryID/" + countryID);
        if (response.Status) {
            yield put(actions.getNetworksByCountryIDSuccess(response.Networks));
        } else {
            yield put(actions.getNetworksByCountryIDFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getNetworksByCountryIDFailed(error.Message));
    }
}

function* getServicesResponse({ payload: { countryID } }) {
    try {
        const response = yield call(getList, "/getServices/" + countryID);
        if (response.Status) {
            yield put(actions.getServicesSuccess(response.Data));
        } else {
            yield put(actions.getServicesFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getServicesFailed(error.Message));
    }
}

function* verifyUtilityIdResponse({ payload: { uID } }) {
    try {
        const response = yield call(getList, "/verifyUtility/" + uID);
        if (response.Status) {
            yield put(actions.verifyUtilityIDSuccess(response.Message,response.UtilityLogo));
        } else {
            yield put(actions.verifyUtilityIDFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.verifyUtilityIDFailed(error.message));
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_COUNTRIES_WHO_HAS_NETWORK, getCountriesWhoHasNetworkResponse),
        takeEvery(actions.GET_NETWORKS_BY_COUNTRY_ID, getNetworksByCountryIdResponse),
        takeEvery(actions.GET_SERVICES, getServicesResponse),
        takeEvery(actions.VERIFY_UTILITY_ID, verifyUtilityIdResponse),
    ]);
}