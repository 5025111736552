const actions = {
    ADD_CONTACT_DETAILS: "ADD_CONTACT_DETAILS",
    ADD_CONTACT_DETAILS_SUCCESS: "ADD_CONTACT_DETAILS_SUCCESS",
    ADD_CONTACT_DETAILS_FAILED: "ADD_CONTACT_DETAILS_FAILED",
    RESET_CONTACT_DETAILS: "RESET_CONTACT_DETAILS",

    addContactDetails: (data) => ({
        type: actions.ADD_CONTACT_DETAILS,
        payload: { data }
    }),
    addContactDetailsSuccess: (contactDetailsMessage) => ({
        type: actions.ADD_CONTACT_DETAILS_SUCCESS,
        contactDetailsMessage
    }),
    addContactDetailsFailed: (contactDetailsError) => ({
        type: actions.ADD_CONTACT_DETAILS_FAILED,
        contactDetailsError
    }),
    resetContactDetails: () => ({
        type: actions.RESET_CONTACT_DETAILS,
    })
}
export default actions;