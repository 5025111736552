import React from 'react'

const Loader = () => {
  return (
    <div id="loading">
    <div id="loading-center">
        {/* <img src={LoaderImage} height={100} /> */}
        Loading....
    </div>
</div>
  )
}

export default Loader
