import React, { useState } from 'react';
const PopConfirm = ({message, onConfirm, onCancel,buttonTitle,width }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    setIsOpen(false);
    onConfirm();
  };

  const handleCancel = () => {
    setIsOpen(false);
    onCancel();
  };

  return (
    <div className="relative inline-block text-left">
     {isOpen && (
        <div  className={`origin-top-right absolute right-0 mt-2 w-[${width}] rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 `} style={{marginTop:'-120px', zIndex:'9999'}}>
          <div className="py-1 w-full" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            <div className=" hgjfh" >
              <p className="text-sm text-center">{message}</p>
            </div>
            <div className="border-t border-gray-200"></div>
            <div className="flex justify-end px-4 py-3">
              <button
                onClick={handleCancel ? handleCancel : ''}
                className="ml-2 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-[#888888]"
              >
                No
              </button>
              <button
                onClick={handleConfirm}
                className="ml-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#662e91] hover:bg-[#5d2d82]"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-[#c04959] hover:bg-[#c04959] "
      >
       {buttonTitle}
      </button>

     
    </div>
  );
};

export default PopConfirm;
