import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ResetPass from './../../assets/images/resetpss-icon.png'
import MohImage from './../../assets/images/moh-logo-blue-(1).png'
import Button from '../../components/utils/Button'
import AuthActions from '../../redux/auth/action'
import { useDispatch, useSelector } from "react-redux";
import Alert from '../../components/utils/Alert';
import { PASSWORD_VALIDATE } from "../../helper/constant"
const { resetPassword, authReset } = AuthActions;

const ResetPassword = () => {
    const dispatch = useDispatch();
    const { loading, resetPasswordMessage, resetPasswordError } = useSelector((state) => state.auth);
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const password = watch('password', '');
    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('forgetPassword');
    const userId = searchParams.get('id');

    const handleFormSubmit = (data) => {
        const newData = {
            id: userId,
            newPassword: data.password,
            token: token
        }
        dispatch(resetPassword(newData));
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(authReset());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(authReset());
    }

    useEffect(() => {
        if (resetPasswordError !== null && resetPasswordError !== undefined) {
            displayAlert(resetPasswordError, 'alert-danger');
        }
        if (resetPasswordMessage !== null && resetPasswordMessage !== undefined) {
            displayAlert(resetPasswordMessage, 'alert-success');
            reset();
        }
    }, [resetPasswordError, resetPasswordMessage]);

    return (
        <div className='moh-conect-bg from-[#0f172a] to-[#0f172a]'>
            <div className="flex min-h-full  flex-1 flex-col justify-center px-6 py-12 lg:px-8">

                <div class="w-8/12 container md:mx-auto">
                    <div class="grid grid-cols-1 gap-1 ">
                        <div className='flex w-full bg-white login-moh-main'>
                            <div className='w-[45%] bg-[#223b8b] rounded-l-lg-moh'>

                                <div className='mt-[1.5rem] h-16'>
                                    <div class="ribbon">
                                        <span class="ribbon3"><a href="/"><img src={MohImage} alt="mockup" width={150} className='mx-auto ' /></a></span>
                                    </div>
                                </div>
                                <div className='mt-[0.7rem]'><a href="/"><img src={ResetPass} alt="mockup" width={270} className='mx-auto' /></a></div>
                            </div>
                            <div className='w-[60%]'>
                                {showAlert && <div className='mt-[35px] mb-[10px] w-[65%] mx-auto'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
                                < div className="mt-5 mb-5 sm:mx-auto sm:w-full sm:max-w-sm">
                                    <h2 className="mt-[1.5rem] mb-[0.9rem] text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                        Reset Your Password?
                                    </h2>
                                    <p class="mb-4 xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xs text-[#727272]">
                                        Your new password must be different from previous used passwords. !
                                    </p>
                                    <form onSubmit={handleSubmit(handleFormSubmit)}>
                                        <div className="mb-5">
                                            <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                                                Password
                                            </label>
                                            <div className="relative">
                                                <input
                                                    type={passwordShow ? 'text' : 'password'}
                                                    {...register('password', {
                                                        required: true,
                                                        minLength: 4,
                                                        validate: (value) => {
                                                            return PASSWORD_VALIDATE.test(value)
                                                        }
                                                    })}
                                                    // placeholder="Password"
                                                    className="w-full rounded-md border bordder-[#E9EDF4] py-2 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:[#f5f3ff94] hover:bg-slate-100"
                                                />

                                                {errors.password && (
                                                    <div className="text-red-500 text-left text-xs">
                                                        {errors.password.type === "required" && "Password is required!"}
                                                        {errors.password.type === "validate" && "Invalid password format (Example@123)!"}
                                                        {errors.password.type === "minLength" && "Password must be at least 4 characters long!"}
                                                    </div>
                                                )}
                                                {/* {errors.password && errors.password.type === "required" != '' ? <div className="text-red-500 text-left text-xs">Password is required !</div> : ''}
                                                {errors.password && errors.password.type === "validate" != '' ? <div className="text-red-500 text-left text-xs">Invalid password format(Example@123) !</div> : ''} */}

                                                {passwordShow ? <svg onClick={() => setPasswordShow((pre) => !pre)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor" className="absolute cursor-pointer" style={{ top: '13px', right: '15px' }}><path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z"></path></svg> :
                                                    <svg onClick={() => setPasswordShow((pre) => !pre)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor" className="absolute cursor-pointer" style={{ top: '13px', right: '15px' }}><path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z"></path></svg>
                                                }
                                            </div>
                                        </div>
                                        <div className="mb-6">
                                            <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                                                Confirm Password
                                            </label>
                                            <div className="relative">
                                                <input
                                                    type={confirmPasswordShow ? 'text' : 'password'}
                                                    // placeholder="Confirm Password"
                                                    {...register('confirmPassword', {
                                                        required: true,
                                                        validate: (value) => {
                                                            return value === password;
                                                        }
                                                    })}
                                                    className="rounded-md w-full border border-[#E9EDF4] py-2 px-5 bg-[#FCFDFE] text-base text-body-color placeholder-[#ACB6BE] outline-none focus-visible:shadow-none focus:[#f5f3ff94]  hover:bg-slate-100"
                                                />
                                                {errors.confirmPassword && errors.confirmPassword.type === "required" != '' ? <div className="text-red-500 text-left text-xs ">Confrim password is required !</div> : ''}
                                                {errors.confirmPassword && errors.confirmPassword.type === "validate" != '' ? <div className="text-red-500 text-left text-xs ">Confrim Password not matched !</div> : ''}

                                                {confirmPasswordShow ? <svg onClick={() => setConfirmPasswordShow((pre) => !pre)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor" className="absolute cursor-pointer" style={{ top: '13px', right: '15px' }}><path d="M12.0003 3C17.3924 3 21.8784 6.87976 22.8189 12C21.8784 17.1202 17.3924 21 12.0003 21C6.60812 21 2.12215 17.1202 1.18164 12C2.12215 6.87976 6.60812 3 12.0003 3ZM12.0003 19C16.2359 19 19.8603 16.052 20.7777 12C19.8603 7.94803 16.2359 5 12.0003 5C7.7646 5 4.14022 7.94803 3.22278 12C4.14022 16.052 7.7646 19 12.0003 19ZM12.0003 16.5C9.51498 16.5 7.50026 14.4853 7.50026 12C7.50026 9.51472 9.51498 7.5 12.0003 7.5C14.4855 7.5 16.5003 9.51472 16.5003 12C16.5003 14.4853 14.4855 16.5 12.0003 16.5ZM12.0003 14.5C13.381 14.5 14.5003 13.3807 14.5003 12C14.5003 10.6193 13.381 9.5 12.0003 9.5C10.6196 9.5 9.50026 10.6193 9.50026 12C9.50026 13.3807 10.6196 14.5 12.0003 14.5Z"></path></svg> :
                                                    <svg onClick={() => setConfirmPasswordShow((pre) => !pre)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="18" height="18" fill="currentColor" className="absolute cursor-pointer" style={{ top: '13px', right: '15px' }}><path d="M17.8827 19.2968C16.1814 20.3755 14.1638 21.0002 12.0003 21.0002C6.60812 21.0002 2.12215 17.1204 1.18164 12.0002C1.61832 9.62282 2.81932 7.5129 4.52047 5.93457L1.39366 2.80777L2.80788 1.39355L22.6069 21.1925L21.1927 22.6068L17.8827 19.2968ZM5.9356 7.3497C4.60673 8.56015 3.6378 10.1672 3.22278 12.0002C4.14022 16.0521 7.7646 19.0002 12.0003 19.0002C13.5997 19.0002 15.112 18.5798 16.4243 17.8384L14.396 15.8101C13.7023 16.2472 12.8808 16.5002 12.0003 16.5002C9.51498 16.5002 7.50026 14.4854 7.50026 12.0002C7.50026 11.1196 7.75317 10.2981 8.19031 9.60442L5.9356 7.3497ZM12.9139 14.328L9.67246 11.0866C9.5613 11.3696 9.50026 11.6777 9.50026 12.0002C9.50026 13.3809 10.6196 14.5002 12.0003 14.5002C12.3227 14.5002 12.6309 14.4391 12.9139 14.328ZM20.8068 16.5925L19.376 15.1617C20.0319 14.2268 20.5154 13.1586 20.7777 12.0002C19.8603 7.94818 16.2359 5.00016 12.0003 5.00016C11.1544 5.00016 10.3329 5.11773 9.55249 5.33818L7.97446 3.76015C9.22127 3.26959 10.5793 3.00016 12.0003 3.00016C17.3924 3.00016 21.8784 6.87992 22.8189 12.0002C22.5067 13.6998 21.8038 15.2628 20.8068 16.5925ZM11.7229 7.50857C11.8146 7.50299 11.9071 7.50016 12.0003 7.50016C14.4855 7.50016 16.5003 9.51488 16.5003 12.0002C16.5003 12.0933 16.4974 12.1858 16.4919 12.2775L11.7229 7.50857Z"></path></svg>
                                                }
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <Button title={'Change Passwoord'} loading={loading} buttonClass="flex w-full justify-center rounded-md bg-[#223b8b] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#223b8bda] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" buttonType='submit' />
                                        </div>
                                    </form>
                                    <div className="mt-5 text-center ">
                                        <div><span href="#">Back to login ? <a href='/login' className="text-[#662e91]"><u>Log In</u></a>  </span></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ResetPassword
