import { useEffect, useMemo, useState } from 'react'
import rechargeSvg from './../../assets/images/clouds.png'
import { useDispatch, useSelector } from 'react-redux'
import HomeActions from '../../redux/home/action';
import RechargeActions from '../../redux/recharge/actions'
import { makePayment } from '../../helper/stripe';
import { isAuthenticated } from '../../helper/commonFunction';
import Button from '../../components/utils/Button';
import NoImage from '../../assets/images/no_image.png';
import { twoDigitDecimal } from '../../helper/commonFunction';

const { getCountriesWhoHasNetwork, getNetworksByCountryID, setFormData } = HomeActions;
const { getPlanByNetwork, createOrder, resetRecharge, selectedNetwork } = RechargeActions;
const BASE_URL = process.env.REACT_APP_BASEURL;

const DEFAULT_COUNTRY_ID = 233;

const checkField = (val) => (val != '' && val != 0);

const Recharge = () => {
    const dispatch = useDispatch();
    const { getNetworksByCountryIdList, getCountriesWhoHasNetworkList, formObject, selectedCountryID } = useSelector((state) => state.home);
    const { planResult, createOrderMessage, createOrderData, createOrderLoading, selectedId, } = useSelector((state) => state.recharge);
    const [selectedPlan, setSelectedPlan] = useState(new Object());
    const [countryID, setCountryID] = useState(DEFAULT_COUNTRY_ID);
    const [networkID, setNetworkID] = useState(0);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [planID, setPlanID] = useState(0);
    const [amount, setAmount] = useState(0);
    const [conversionRate, setConversionRate] = useState(1);
    const [error, setError] = useState('');

    useEffect(() => {
        dispatch(resetRecharge());
        dispatch(getCountriesWhoHasNetwork());
        dispatch(getPlanByNetwork(selectedId));
    }, []);

    useEffect(() => {
        const countryIDToUse = selectedCountryID || 233;
        dispatch(getNetworksByCountryID(countryIDToUse));
    }, [selectedCountryID]);

    useEffect(() => {
        if (createOrderData.ID && createOrderData.OrderNumber) {
            localStorage.setItem('payment', 1);
            const products = [
                {
                    name: createOrderData.PlanName,
                    price: createOrderData.ChargedAmount,
                    image: `${BASE_URL}/images/network/${createOrderData.NetworkLogoURL}`,
                    quantity: 1
                }
            ]
            makePayment(products, createOrderData.PaymentTxnID);
        }
    }, [createOrderData]);

    // const handleCountryChange = (e) => {
    //     let countryId = e.target.value;
    //     setCountryID(countryId);
    //     dispatch(getNetworksByCountryID(countryId));
    // };

    const handleNetworkChange = (e) => {
        let networkId = e.target.value;
        let selectedNetwork = getNetworksByCountryIdList.filter((item) => item.ID == networkId);
        setCountryID(selectedNetwork[0].CountryID);
        setConversionRate(selectedNetwork[0].ConversionRate);
        setSelectedPlan(new Object());
        setNetworkID(networkId);
        dispatch(getPlanByNetwork(networkId));
    }
    const handlePlanSelect = (item, id) => {
        setPlanID(id);
        setSelectedPlan(item);
        if (item.IsRange == 0) {
            setAmount(item.PlanPrice)
        } else {
            setAmount('');
        }
    };


    const handleAmountChange = (inputAmount) => {
        setAmount(inputAmount);
        if (selectedPlan.IsRange == 1) {
            const minAmount = selectedPlan.MinTopUpAmount;
            const maxAmount = selectedPlan.MaxTopUpAmount;
            if (inputAmount < minAmount || inputAmount > maxAmount) {
                return false;
            } else {
                return true;

            }
        } else {
            return true;
        }
    };


    const allFieldValid = useMemo(() => {
        if (checkField(countryID) && (checkField(networkID) || checkField(selectedId)) && checkField(phoneNumber) && checkField(planID) && handleAmountChange(amount)) {
            return true;
        } else {
            return false;
        }
    }, [countryID, networkID, phoneNumber, planID, amount]);

    const onSubmit = (e) => {
        e.preventDefault();
        const data = {
            countryID,
            networkID: networkID ? networkID : selectedId,
            phoneNumber,
            planID,
            amount
        }
        if (isAuthenticated()) {
            dispatch(createOrder(data));
        } else {
            window.location.href = `/login`;
        }
    };

    return (
        <>
            <section className="wrapper !bg-[#8ca0e12e]">
                <div className="container mx-auto">
                    <div className='py-14' >
                        <h1 className="pt-32 xl:text-[2.5rem] text-[calc(1.375rem_+_1.5vw)] !leading-[1.15] font-bold  xl:!mr-5 xl:mt-[-2.5rem] lg:mt-[-2.5rem] text-center" data-cue="slideInDown" data-group="page-title" data-delay="900" data-show="true" >Recharge <br className="hidden md:block xl:hidden lg:hidden" /><span className="text-[#1d89ca] ">Moh Connect</span></h1>
                    </div>
                </div>
                <figure className="m-0 p-0"><img className="w-full max-w-full !h-auto" src={rechargeSvg} alt="image" /></figure>
            </section>

            <section id="snippet-2" className="wrapper !bg-[#ffffff]  border-b-[rgba(164,174,198,0.2)] border-b border-solid px-32">
                <div className="container mx-auto   pb-16 xl:pb-16 lg:pb-16 md:pb-20">
                    <div className=' mx-auto w-3/4 bg- from-white rounded-md'>
                        <div className="after:mt-4 after:block after:h-1 after:w-full after:rounded-lg after:bg-gray-200"><ol className="grid grid-cols-3 text-sm font-medium text-gray-500"><li className="relative flex justify-start "><span className="absolute -bottom-[2.1rem] start-0 rounded-full bg-[#223b8b] text-white"><svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"> <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path> </svg></span><span className="py-2 px-4 text-xl"> 1 </span></li><li className="relative flex justify-center "><span className="absolute -bottom-[2.1rem] left-1/2 -translate-x-1/2 rounded-full bg-gray-600 text-white"><svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"> <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path> </svg></span><span className="py-2 text-xl"> 2 </span></li><li className="relative flex justify-end"><span className="absolute -bottom-[2.1rem] end-0 rounded-full bg-gray-600 text-white"><svg className="h-10 w-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"> <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path> </svg></span><span className="py-2 px-4 text-xl"> 3 </span></li></ol></div>
                    </div>

                    <div className="my-8"><h1 className="text-center xl:text-[1.8rem] lg:text-[1.8rem] font-bold text-[#1d89ca]">Step 1 : Select product</h1><p className="text-center xl:text-lg lg:text-sm text-[#223b8b] font-[600] mt-2">Next: Review and payment</p></div>

                    <form onSubmit={onSubmit}>
                        <div className='w-3/4 mx-auto'>
                            {/* <label htmlFor="countryId" className="block mb-3 pt-[30px] text-xl text-gray-900 text-[22px] font-[600]">Country</label>
                            <div className='w-[50%]'>
                                <select id="countryId" name='countryId' onChange={(e) => handleCountryChange(e)} className="bg-gray-50 border py-4 text-[18px] border-gray-300 text-gray-900 rounded-lg focus:ring-[#223b8b] focus:border-[#223b8b] block w-full p-2.5">
                                    {getCountriesWhoHasNetworkList.length > 0 && getCountriesWhoHasNetworkList.map((item) => {
                                        return <option selected={item.ID == DEFAULT_COUNTRY_ID} key={`country${item.ID}`} value={item.ID}> {item.CountryName} </option>;
                                    })}
                                </select>
                            </div> */}

                            <div className='py-[15px]'>
                                <h3 className='text-[#223b8b] font-[500] text-[20px]  mb-3'>Operator</h3>
                                <div>
                                    {getNetworksByCountryIdList && getNetworksByCountryIdList.length > 0 && getNetworksByCountryIdList.map((item) => (
                                        <label className="custom-radio" key={`network${item.ID}`} title={item.NetworkName}  >
                                            <input type="radio" checked={selectedId == item.ID} name="networkId" value={item.ID} onChange={(e) => handleNetworkChange(e)} />
                                            <span className="radio-btn">
                                                {selectedId == item.ID ? <span className="icon_select"><i className="ri-check-line"></i></span> : ''}
                                                <div className="hobbies-icon">
                                                    <img src={item.NetworkLogoURL ? `${BASE_URL}/images/network/${item.NetworkLogoURL}` : NoImage} alt={item.NetworkName} />
                                                </div>
                                            </span>
                                        </label>
                                    ))}
                                </div>
                            </div>

                            <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 py-[50px]"
                                id="phoneNumberSection">
                                <h2 className="text-start pb-2 xl:text-xl lg:text-sm font-semibold">Phone Number</h2>
                                <div className="flex flex-col">
                                    <div className="relative xl:w-1/2 lg:w-1/2 md:w-1/2 w-full">
                                        <input
                                            type='text'
                                            placeholder='Phone Number..'
                                            className="input-outline p-2 border border-gray-300 rounded w-full"
                                            onChange={(e) => setPhoneNumber(e.target.value)}
                                            maxLength={10}
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>

                        {
                            planResult && planResult.length > 0 &&
                            <div className='w-3/4 mx-auto mb-[30px] grid grid-cols-3 gap-[1.1rem]'>
                                {planResult.map(item => (
                                    <div className="selection-wrapper" key={`planId-${item.ID}`}>
                                        <div key={`plan-key-${item.ID}`} onClick={() => handlePlanSelect(item, item.ID)} style={{ cursor: 'pointer' }}>
                                            <label htmlFor={`planId-${item.ID}`} className="selected-label">
                                                <input type="radio" name="planId" value={item.ID} id={`planId-${item.ID}`} />
                                                {selectedPlan.ID == item.ID ? <span className="icon"><i className="ri-check-line"></i></span> : ''}
                                                <div className=" flex flex-col w-[19rem] p-4 border-2 border-gray-40s0 selected-content">
                                                    <span className="text-xl font-semibold leading-10">{item.PlanName}</span>
                                                    <span className="text-xs font-semibold uppercase">{item.PlanDesc}</span>
                                                    <ul className="text-sm mt-2 flex items-center justify-between">
                                                        <li>Amount Charged:</li>
                                                        <li className='text-xl font-semibold'>${item.PlanPrice.toFixed(2)}</li>
                                                    </ul>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        }


                        {
                            selectedPlan.IsRange == 1 ?
                                <div className="grid grid-cols-1 xl:grid-cols-1 lg:grid-cols-1 md:grid-cols-1 sm:grid-cols-1 py-[50px] w-3/4 mx-auto" id="amountSection" >
                                    <h2 className="text-start pb-2 xl:text-xl lg:text-sm font-semibold">Amount</h2>
                                    <div className="flex flex-row justify-between">
                                        <div className="relative xl:w-1/2 lg:w-1/2 md:w-1/2 w-full">
                                            <input
                                                className="block p-2.5 py-3 w-full z-20 text-sm text-gray-900 outline-none bg-gray-50 rounded-lg rounded-s-gray-100 rounded-s-2 border border-gray-300 focus:ring-[#223b8b] focus:border-[#223b8b]"
                                                id="amount"
                                                placeholder="Amount"
                                                name="amount"
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                            />
                                        </div>
                                        {
                                            countryID != 233 && (amount && amount > 0) && <p className='text-center'>Receiver will get {twoDigitDecimal(amount * conversionRate)} xof</p>
                                        }
                                    </div>
                                </div> : ''
                        }
                        {/* <button type='submit' className={`w-[300px] h-[43px] mx-auto mt-[80px] flex align-center justify-center relative px-10 py-3 font-medium text-white transition duration-300 rounded-md ${allFieldValid ? 'bg-[#223b8be6] hover:bg-[#223b8b]' : 'bg-[#95a5a6]'} ease btn-padd-n cursor-pointer`} disabled={!allFieldValid} > <span className="relative text-center">Recharge Now</span> </button> */}

                        <Button
                            title={'Recharge Now'}
                            loading={createOrderLoading}
                            buttonClass={`w-[300px] h-[43px] mx-auto mt-[80px] flex align-center justify-center relative px-10 py-3 font-medium text-white transition duration-300 rounded-md ${allFieldValid ? 'bg-[#223b8be6] hover:bg-[#223b8b]' : 'bg-[#95a5a6]'} ease btn-padd-n cursor-pointer`}
                            buttonType={'submit'}
                            disable={!allFieldValid}
                        />
                    </form>

                </div>
            </section>
        </>
    )
}

export default Recharge;