import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Alert from '../../components/utils/Alert';
import AuthActions from '../../redux/auth/action';
import { useNavigate } from "react-router-dom";
import CheckMark from '../../assets/images/checkmark.png';
import Cross from '../../assets/images/cross.png';




const { verifyEmail, authReset } = AuthActions;


const VerifyEmail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [verified, setVerified] = useState(false)
  const [failed, setFailed] = useState(false)
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const { verifyError, verifyMessage, } = useSelector((state) => state.auth);
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('verifytoken');


  useEffect(() => {
    if (token) {
      VerifyToken(token);
    }
  }, []);

  const VerifyToken = async (token) => {
    try {
      dispatch(verifyEmail(token));
    } catch (error) {
      setVerified(false)
      setFailed(true)
    }
  }

  useEffect(() => {
    if (verifyError !== null && verifyError !== undefined) {
      displayAlert(verifyError, 'alert-danger');
    }
    if (verifyMessage !== null && verifyMessage !== undefined) {
      displayAlert(verifyMessage, 'alert-success');
    }
  }, [verifyError, verifyMessage]);

  const displayAlert = (text, type) => {
    if (type === 'alert-danger') {
      setFailed(true);
      setVerified(false);
    }
    else {
      setVerified(true);
      setFailed(false);
    }
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
    dispatch(authReset());
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
    dispatch(authReset());
  }


  const handleLogin = () => {
    dispatch(authReset());
    navigate("/login");
    setFailed(false)
    setVerified(false)
  }

  return (
    <div>
      {/* {showAlert && <div className='mt-2'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>} */}

      {verified ? <section className='py-32 mx-auto bg-gray-100 h-screen'>
        <div className="m-2 mx-auto flex items-center justify-center">
          <div className="component flex max-w-sm pt-16">
            <div className="w-full px-8 pb-8 shadow-lg rounded-lg bg-white text-center relative">
              <div className="absolute top-0 left-0 right-0 ">
                <div className="inline-block bg-[#cfe7df91] rounded-full p-2 transform -translate-y-1/2">
                  <img
                    className='transition duration-300 hover:scale-110'
                    src={CheckMark}
                    width={80}
                    height={80}
                    alt="Picture of the a&t"
                    unoptimized
                  />
                </div>
              </div>
              <div className="mt-20 uppercase text-[#431e61] font-bold">Email verified</div>
              <div className="mt-6 text-sm text-gray-600">Your email has been verified successfully.<br />Thank You</div>

              <button onClick={handleLogin} className='mt-4 w-full
                 rounded-md
                 border
                 bordder-primary
                 py-2
                 px-5
                 bg-[#223b8b]
                 text-base text-white
                 cursor-pointer
                 hover:bg-opacity-90
                 transition'>Log-in</button>
            </div>
          </div>
        </div>
      </section> : ''}

      {failed ? <section className='py-10 mx-auto bg-gray-100 h-screen'>
        <div className="m-2 mx-auto flex items-center justify-center">
          <div className="component flex max-w-sm pt-16">
            <div className="w-full px-8 pb-8 shadow-lg rounded-lg bg-white text-center relative">
              <div className="absolute top-0 left-0 right-0 ">
                <div className="inline-block bg-[#ffdbdb] rounded-full p-2 transform -translate-y-1/2">
                  <img
                    className='transition duration-300 hover:scale-110'
                    src={Cross}
                    width={80}
                    height={80}
                    alt="Picture of the a&t"
                    unoptimized
                  />
                </div>
              </div>
              <div className="mt-20 uppercase text-[#431e61] font-bold">Something went wrong!</div>
              <div className="mt-6 text-sm text-gray-600">Linked has been expired</div>

              <button onClick={handleLogin} className='mt-4 w-full
                 rounded-md
                 border
                 bordder-primary
                 py-2
                 px-5
                 bg-[#223b8b]
                 text-base text-white
               
                 cursor-pointer
                 hover:bg-opacity-90
                 transition'>Sign-in</button>
            </div>
          </div>
        </div>
      </section> : ''}


    </div>
  )
}

export default VerifyEmail
