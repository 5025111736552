'use client'
import React from 'react'
import { getAuth, signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { app } from './firebase'
import AuthActions from '../../redux/auth/action'
import { useDispatch, useSelector } from 'react-redux'

const { googleAuth, googleAuthFailed } = AuthActions;

function FacebookAuth() {
    const dispatch = useDispatch();
    const handleFacebookClick = async () => {
        try {
            const provider = new FacebookAuthProvider()
            const auth = getAuth(app);
            const result = await signInWithPopup(auth, provider)

            //console.log(result)

            const newData = {
                name: result.user.displayName,
                email: result.user.email,
                authType: "52"
            }

            dispatch(googleAuth(newData));
        } catch (error) {
            // console.log('could not sign in with facebook', error);
            dispatch(googleAuthFailed(error.message));
        }
    }
    return (

        <span onClick={handleFacebookClick}
            className="flex items-center justify-center cursor-pointer w-10 h-10 border rounded-full text-dark hover:border-primary hover:bg-[#eeeded] border-stroke hover:text-white"
        >
            <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="40" height="40" viewBox="0 0 48 48">
                <path fill="#3f51b5" d="M24 4A20 20 0 1 0 24 44A20 20 0 1 0 24 4Z"></path><path fill="#fff" d="M29.368,24H26v12h-5V24h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H30v4h-2.287 C26.104,16,26,16.6,26,17.723V20h4L29.368,24z"></path>
            </svg>
        </span>
    )
}

export default FacebookAuth