import { Navigate } from 'react-router-dom';
import { isAuthenticated } from './commonFunction';

const paymentStatus = localStorage.getItem('payment') ? localStorage.getItem('payment') : null;

export const UnAuthenticatedRoute = ({ children }) => {
    if (!isAuthenticated()) {
        return <>{children}</>
    } else {
        return <Navigate to='/' />
    }
}

export const AuthenticatedRoute = ({ children }) => {
    if (isAuthenticated()) {
        return <>{children}</>
    } else {
        return <Navigate to='/' />
    }
}

export const PaymentRoute = ({ children }) => {
    if (paymentStatus == 1) {
        return <>{children}</>
    } else {
        return <Navigate to='/' />
    }
}
