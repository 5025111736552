import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./action";
import { postLogin, getList, addData } from "../../helper/fackBackend_Helper";

function* signInResponse({ data }) {
  try {
    const response = yield call(postLogin, '/sign-in', data);
    if (response.response.Status) {
      yield put(actions.signInSuccess(response.response.Token));
    } else {
      yield put(actions.signInFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.signInFailed(error));
  }
}

function* signUpResponse({ data }) {
  try {
    const response = yield call(postLogin, `/sign-up`, data);
    if (response.response.Status) {
      yield put(actions.signUpSuccess(response.response.Message));
    } else {
      yield put(actions.signUpFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.signUpFailed(error));
  }
}

function* googleAuthResponse({ data }) {
  try {
    const response = yield call(postLogin, '/auth/google', data);
    if (response.response.Status) {
      yield put(actions.googleAuthSuccess(response.response.Token));
    } else {
      yield put(actions.googleAuthFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.googleAuthFailed(error));
  }
}

function* signoutResponse() {
  try {
    const response = yield call(addData, '/sign-out');
    if (response.Status) {
      yield put(actions.signoutSuccess(response.Message));
    } else {
      yield put(actions.signoutFailed(response.Message));
    }
  } catch (error) {
    yield put(actions.signoutFailed(error));
  }
}


function* forgotPasswordResponse({ data }) {
  try {
    const response = yield call(addData, '/forget-password', data);
    if (response.response.Status) {
      yield put(actions.forgotPasswordSuccess(response.response.Message));
      // console.log("response", response)
    } else {
      yield put(actions.forgotPasswordFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.forgotPasswordFailed(error));
  }
}

function* verifyEmailResponse({ data }) {
  try {
    const response = yield call(addData, '/verify-email/' + data);
    if (response.response.Status) {
      yield put(actions.verifyEmailSuccess(response.response.Message));
    } else {
      yield put(actions.verifyEmailFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.verifyEmailFailed(error));
  }
}

function* resetPasswordResponse({ data }) {
  try {
    const response = yield call(addData, '/reset-password', data);
    if (response.response.Status) {
      yield put(actions.resetPasswordSuccess(response.response.Message));
    } else {

      yield put(actions.resetPasswordFailed(response.response.Message));
    }
  } catch (error) {
    yield put(actions.resetPasswordFailed(error));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.SIGN_IN, signInResponse),]);
  yield all([takeEvery(actions.SIGN_UP, signUpResponse),]);
  yield all([takeEvery(actions.GOOGLE_AUTH, googleAuthResponse),]);
  yield all([takeEvery(actions.SIGN_OUT, signoutResponse),]);
  yield all([takeEvery(actions.FORGOT_PASSWORD, forgotPasswordResponse),]);
  yield all([takeEvery(actions.VERIFY_EMAIL, verifyEmailResponse),]);
  yield all([takeEvery(actions.RESET_PASSWORD, resetPasswordResponse),]);
}