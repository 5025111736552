const actions = {
    GET_COUNTRIES_WHO_HAS_NETWORK: 'GET_COUNTRIES_WHO_HAS_NETWORK',
    GET_COUNTRIES_WHO_HAS_NETWORK_SUCCESS: 'GET_COUNTRIES_WHO_HAS_NETWORK_SUCCESS',
    GET_COUNTRIES_WHO_HAS_NETWORK_FAILED: 'GET_COUNTRIES_WHO_HAS_NETWORK_FAILED',
    GET_NETWORKS_BY_COUNTRY_ID: 'GET_NETWORKS_BY_COUNTRY_ID',
    GET_NETWORKS_BY_COUNTRY_ID_SUCCESS: 'GET_NETWORKS_BY_COUNTRY_ID_SUCCESS',
    GET_NETWORKS_BY_COUNTRY_ID_FAILED: 'GET_NETWORKS_BY_COUNTRY_ID_FAILED',
    SET_FORM_DATA: 'SET_FORM_DATA',
    GET_SERVICES: 'GET_SERVICES',
    GET_SERVICES_SUCCESS: 'GET_SERVICES_SUCCESS',
    GET_SERVICES_FAILED: 'GET_SERVICES_FAILED',
    VERIFY_UTILITY_ID: 'VERIFY_UTILITY_ID',
    VERIFY_UTILITY_ID_SUCCESS: 'VERIFY_UTILITY_ID_SUCCESS',
    VERIFY_UTILITY_ID_FAILED: 'VERIFY_UTILITY_ID_FAILED',

    verifyUtilityID: (uID) => ({
        type: actions.VERIFY_UTILITY_ID,
        payload: { uID }
    }),
    verifyUtilityIDSuccess: (message,utilitylogoUrl) => ({
        type: actions.VERIFY_UTILITY_ID_SUCCESS,
        payload: { message ,utilitylogoUrl}
    }),
    verifyUtilityIDFailed: (error) => ({
        type: actions.VERIFY_UTILITY_ID_FAILED,
        payload: { error }
    }),


    setFormData: (key, value) => ({
        type: actions.SET_FORM_DATA,
        payload: { key, value }
    }),

    getNetworksByCountryID: (countryID) => ({
        type: actions.GET_NETWORKS_BY_COUNTRY_ID,
        payload: { countryID }
    }),
    getNetworksByCountryIDSuccess: (data) => ({
        type: actions.GET_NETWORKS_BY_COUNTRY_ID_SUCCESS,
        payload: { data }
    }),
    getNetworksByCountryIDFailed: (error) => ({
        type: actions.GET_NETWORKS_BY_COUNTRY_ID_FAILED,
        payload: { error }
    }),

    getCountriesWhoHasNetwork: () => ({
        type: actions.GET_COUNTRIES_WHO_HAS_NETWORK,
    }),
    getCountriesWhoHasNetworkSuccess: (data) => ({
        type: actions.GET_COUNTRIES_WHO_HAS_NETWORK_SUCCESS,
        payload: { data }
    }),
    getCountriesWhoHasNetworkFailed: (error) => ({
        type: actions.GET_COUNTRIES_WHO_HAS_NETWORK_FAILED,
        payload: { error }
    }),

    getServices: (countryID) => ({
        type: actions.GET_SERVICES,
        payload: { countryID }
    }),
    getServicesSuccess: (data) => ({
        type: actions.GET_SERVICES_SUCCESS,
        payload: { data }
    }),
    getServicesFailed: (error) => ({
        type: actions.GET_SERVICES_FAILED,
        payload: { error }
    }),
};
export default actions;