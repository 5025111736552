import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import paymentSuccess from '../../assets/gif/payment-success.gif'
import rechargeSuccess from '../../assets/gif/recharge-success.gif'
import rechargeFailed from '../../assets/gif/recharge-failed.gif'
import rechargeProcess from '../../assets/gif/recharge-process.gif'

import RechargeActions from '../../redux/recharge/actions';

const { processOrder, completeOrder } = RechargeActions;

const getURLData = (key) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  return urlParams.get(key);
}

const PaymentSuccess = () => {
  const dispatch = useDispatch();
  const { processOrderMessage, completeOrderLoading, completeOrderError, completeOrderMessage } = useSelector((state) => state.recharge);
  const [first, setFirst] = useState(true);
  const [textGifData, setTextGifData] = useState({
    message: "Loading..",
    color: "#3498db",
    gif: 0
  });

  useEffect(() => {
    localStorage.removeItem('payment');
    const payment_id = getURLData('payment_id')
    const session_id = getURLData('session_id')

    const data = {
      paymentTxnID: payment_id,
      stripeSessionID: session_id,
    }
    dispatch(processOrder(data));
  }, []);

  useEffect(() => {
    if (processOrderMessage && first) {
      setFirst(false);
      const payment_id = getURLData('payment_id');
      dispatch(completeOrder({
        paymentTxnID: payment_id,
      }));

    }
  }, [processOrderMessage]);

  const backToHome = () => {
    window.location.href = '/recharge';
  }

  useEffect(() => {
    if (completeOrderLoading == false && completeOrderError == null && completeOrderMessage == null) {
      setTextGifData({
        message: "Your payment was successful",
        color: '#3498db',
        gif: 1
      });
    }

    if (completeOrderLoading == true) {
      setTextGifData({
        message: "Under process..",
        color: "#f1c40f",
        gif: 2
      });
    } else if (completeOrderLoading == false && (completeOrderError || completeOrderMessage)) {
      setTextGifData({
        message: completeOrderError ? completeOrderError : completeOrderMessage,
        color: completeOrderError ? '#e74c3c' : '#2ecc71',
        gif: completeOrderError ? 3 : 4,
      });
    }

  }, [completeOrderLoading, completeOrderError, completeOrderMessage]);

  return (
    <>
      <section className='bg-[#f8fdf9bf] bg-success-1 flex items-center justify-center' style={style.section}>
        <div className="container mx-auto flex items-center justify-center">
          <div className='w-1/3'>
            <div className='w-full bg-white rounded-md shadow-md px-card'>
              <div className='w-[200px] mx-auto'>

                {
                  textGifData.gif == 1 ? <img src={paymentSuccess} style={style.gifStyle} /> :
                    textGifData.gif == 2 ? <img src={rechargeProcess} style={style.gifStyle} /> :
                      textGifData.gif == 3 ? <img src={rechargeFailed} style={style.gifStyle} /> :
                        textGifData.gif == 4 ? <img src={rechargeSuccess} style={style.gifStyle} /> : ''
                }

              </div>
              <div className='pt-[5px] '>
                <h1 className='text-center text-4xl font-medium'>{textGifData.message}</h1>
                {
                  textGifData.gif == 1 || textGifData.gif == 2 ? <>
                    <p className='text-center pt-[15px]'>Recharge under process..</p>
                    <p className='text-center mx-auto text-sm'>Please avoid refreshing the page or navigating to a different one</p>
                  </> : ''
                }

              </div>

              {
                textGifData.gif == 1 ? <div className='text-center mt-[10px]'> <button className={`bg-[#3498db] px-8 py-2 text-[#fff]`} disabled>Continue Recharge</button> </div> :
                  textGifData.gif == 2 ? <div className='text-center mt-[10px]'> <button className={`bg-[#f1c40f] px-8 py-2 text-[#fff]`} disabled>Continue Recharge</button> </div> :
                    textGifData.gif == 3 ? <div className='text-center mt-[20px]'> <button className={`bg-[#e74c3c] px-8 py-2 text-[#fff]`} onClick={() => backToHome()}>Continue Recharge</button> </div> :
                      textGifData.gif == 4 ? <div className='text-center mt-[10px]'> <button className={`bg-[#2ecc71] px-8 py-2 text-[#fff]`} onClick={() => backToHome()}>Continue Recharge</button> </div> : ''
              }

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const style = {
  gifStyle: {
    width: '200px',
    height: '200px'
  },
  section: {
    minHeight: "calc(100vh - 84px)",
    background: "#ecf0f15e"
  }
}

export default PaymentSuccess;