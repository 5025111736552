import React from 'react'
import logoImage from './../../assets/images/moh-logo-blue-(1).png';

const Footer = () => {
    return (
        <footer className="relative before:absolute before:inset-0 before:h-px before:w-96 before:bg-gradient-to-r before:from-yellow-300 before:via-pink-400 before:to-transparent after:absolute after:inset-0 after:ml-auto after:mt-auto after:h-px after:w-96 after:bg-gradient-to-l after:from-yellow-300 after:via-pink-400 after:to-transparent bottom-fixed-fotter">
            <div className='border-y pt-[1rem] pb-3 border-gray-200 bg-gray-100'>
                <div className="m-auto space-y-8 px-4 text-gray-600 sm:px-12 xl:max-w-6xl xl:px-0">
                    <div className=" flex items-center justify-between gap-2 md:gap-0">
                        <div className=" ">
                            <div className="flex h-full items-center justify-between gap-6 border-b border-white py-6 md:flex-col md:items-start md:justify-between md:space-y-6 md:border-none md:py-0">
                                <div>
                                    <a href="/index.html" aria-label="ampire logo" className="flex items-center">
                                        <img src={logoImage} alt="mockup" width={180} />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className=" ">

                        </div>
                        <div className='pt-[13px]'>
                            <div className="flex gap-[0.5rem] col-span-full items-center my-[8px]">
                                <span className='footer-fb-icon transition duration-700 ease-in-out hover:-translate-y-1'><a href="#" target="blank" aria-label="github" className="hover:text-primary">
                                    <svg width="18" height="18" viewBox="0 0 20 20" className="fill-current"><path d="M7.43902 6.4H6.19918H5.75639V5.88387V4.28387V3.76774H6.19918H7.12906C7.3726 3.76774 7.57186 3.56129 7.57186 3.25161V0.516129C7.57186 0.232258 7.39474 0 7.12906 0H5.51285C3.76379 0 2.54609 1.44516 2.54609 3.5871V5.83226V6.34839H2.10329H0.597778C0.287819 6.34839 0 6.63226 0 7.04516V8.90323C0 9.26452 0.243539 9.6 0.597778 9.6H2.05902H2.50181V10.1161V15.3032C2.50181 15.6645 2.74535 16 3.09959 16H5.18075C5.31359 16 5.42429 15.9226 5.51285 15.8194C5.60141 15.7161 5.66783 15.5355 5.66783 15.3806V10.1419V9.62581H6.13276H7.12906C7.41688 9.62581 7.63828 9.41935 7.68256 9.10968V9.08387V9.05806L7.99252 7.27742C8.01466 7.09677 7.99252 6.89032 7.85968 6.68387C7.8154 6.55484 7.61614 6.42581 7.43902 6.4Z"></path></svg>
                                </a></span>

                                <span className='footer-fb-icon transition duration-700 ease-in-out hover:-translate-y-1' id='footer-fb'>
                                    <a href="#" target="blank" aria-label="twitter" className="hover:text-primary">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="white" className="bi bi-twitter" viewBox="0 0 16 16">
                                            <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                                        </svg>
                                    </a>
                                </span>
                                <span className='footer-fb-icon transition duration-700 ease-in-out hover:-translate-y-1' id='footer-fe'>
                                    <a href="#" target="blank" aria-label="medium" className="hover:text-primary">
                                        <svg width="17" height="17" viewBox="0 0 20 20" className="fill-current"><path d="M13.0214 0H1.02084C0.453707 0 0 0.451613 0 1.01613V12.9839C0 13.5258 0.453707 14 1.02084 14H12.976C13.5432 14 13.9969 13.5484 13.9969 12.9839V0.993548C14.0422 0.451613 13.5885 0 13.0214 0ZM4.15142 11.9H2.08705V5.23871H4.15142V11.9ZM3.10789 4.3129C2.42733 4.3129 1.90557 3.77097 1.90557 3.11613C1.90557 2.46129 2.45002 1.91935 3.10789 1.91935C3.76577 1.91935 4.31022 2.46129 4.31022 3.11613C4.31022 3.77097 3.81114 4.3129 3.10789 4.3129ZM11.9779 11.9H9.9135V8.67097C9.9135 7.90323 9.89082 6.8871 8.82461 6.8871C7.73571 6.8871 7.57691 7.74516 7.57691 8.60323V11.9H5.51254V5.23871H7.53154V6.16452H7.55423C7.84914 5.62258 8.50701 5.08065 9.52785 5.08065C11.6376 5.08065 12.0232 6.43548 12.0232 8.2871V11.9H11.9779Z"></path></svg>
                                    </a>
                                </span>

                                <span>&copy; {new Date().getFullYear()}</span>
                                <span>All right reserved</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer