import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import ProfileActions from '../../redux/profile/actions'
import moment from "moment";
import Button from '../../components/utils/Button';

const REACT_APP_DATE_FORMAT = process.env.REACT_APP_DATE_FORMAT;

const { getOrderData, retryOrder } = ProfileActions

const OrderView = () => {
    const dispatch = useDispatch();
    const [orderId, setOrderId] = useState('')
    const { orderDataResult, retryOrderLoading, orderLoading } = useSelector((state) => state.profile);

    useEffect(() => {
        dispatch(getOrderData())
    }, []);

    const handleRetryOrder = (order) => {
        dispatch(retryOrder(order.ID));
        setOrderId(order.ID)
    }

    return (
        <div>
            <div className="p-6 bg-white text-medium text-gray-500 rounded-lg w-full order-view-card">
                <h3 className="text-lg font-bold text-gray-900 mb-2">Orders</h3>
                <div className='overflow-y-auto h-[400px] py-3 mb-[33px]' >
                    {orderLoading ? <div role="status" className='flex justify-center mt-8'>
                        <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span class="sr-only">Loading...</span>
                    </div> : <div className="flex flex-col justify-center gap-2  ">
                        {orderDataResult && orderDataResult.length > 0 ? orderDataResult.map((item, key) => {
                            return (
                                <div className=" order-shd-o relative flex flex-col md:flex-row md:space-x-5 space-y-3 md:space-y-0 rounded-xl shadow-lg p-3 max-w-xs md:max-w-3xl mx-auto border border-white bg-white xl:w-[93%]" key={key}>
                                    <div className="w-full md:w-full bg-white flex flex-col space-y-2 p-3 order-card-no">
                                        <span className='order-no'>
                                            <p className="font-semibold text-md ml-1"> Order Number:  {item.OrderNumber} </p>
                                        </span>
                                        <div className="flex justify-between item-center">
                                            <div className="flex justify-between item-center w-[47%]">
                                                <p className='bg-gray-100 px-3 py-1 rounded-full text-xs font-medium text-gray-800'>{item.Type === null ? 'Mobile Number' : 'Consumer Number'}</p>
                                                <p className='text-gray-600 font-bold text-sm ml-1  md:block'>{item.MSISDN}</p>
                                            </div>

                                            <div className="flex justify-between item-center w-[47%]">
                                                <p className='bg-gray-100 px-3 py-1 rounded-full text-xs font-medium text-gray-800'>{item.Type === null ? 'Order Date' : 'Payment Date'}</p>
                                                <p className='text-gray-600 font-bold text-sm ml-1  md:block'>{moment(item.OrderDate).format(REACT_APP_DATE_FORMAT)}</p>
                                            </div>
                                        </div>
                                        <div className='flex items-center justify-between pt-[0.7rem]'>
                                            <div className="flex justify-between item-center w-[47%]">
                                                <p className='bg-gray-100 px-3 py-1 rounded-full text-xs font-medium text-gray-800 '>Amount</p>
                                                <p className='text-gray-600 font-bold text-sm ml-1  md:block'>{item.CurrencyCode === 'usd' ? <>&#36;</> : "XOF"} {item.NetAmount.toFixed(2)}</p>
                                            </div>


                                            <div className="flex justify-between item-center w-[47%]">
                                                <p className='bg-gray-100  rounded-full text-xs font-medium text-gray-800 p-amount mt-[6]'>Status</p>
                                                <p className={`text-sm font-semiblod mt-[6] ${item.RequestStatus === 23 ? 'text-green-700 font-[500] bg-green-50' : item.RequestStatus === 21 ? 'text-gray-700 font-[500] bg-gray-50' : item.RequestStatus === 22 ? 'text-yellow-700 font-[500] bg-yellow-50' : item.RequestStatus === 24 ? 'text-red-700 font-[500] bg-red-50' : 'text-black bg-white'} px-3 py-1 rounded-full`}>
                                                    {item.OrderStatusValue}
                                                </p>
                                            </div>
                                        </div>

                                        {
                                            item.Remark && item.RetryFlag == 1 &&
                                            <div className='flex items-center justify-between pt-[0.5rem]'>
                                                <div className='flex items-center gap-3 re-position'>
                                                    <span className='text-md font-medium text-gray-800'>Remark:</span>
                                                    <span className='text-gray-700 text-sm'>{item.Remark}</span>
                                                </div>
                                            </div>
                                        }

                                        {
                                            item.RequestStatus == 24 && item.PaymentStatus == 27 && item.RetryFlag == 0 && item.Type === null &&
                                            <div className='re-absout'>
                                                <Button style={{ display: 'flex', margin: '0px 9px 5px 0px' }}
                                                    icon={retryOrderLoading && orderId == item.ID ? '' : <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" className='mt-1 mb-1 mr-1'><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.9" d="M9.11 5.08c.87-.26 1.83-.43 2.89-.43 4.79 0 8.67 3.88 8.67 8.67s-3.88 8.67-8.67 8.67-8.67-3.88-8.67-8.67c0-1.78.54-3.44 1.46-4.82M7.87 5.32L10.76 2M7.87 5.32l3.37 2.46"></path></svg>}
                                                    title={''}
                                                    onClick={() => handleRetryOrder(item)}
                                                    loading={orderId == item.ID && retryOrderLoading}
                                                    buttonClass={`text-white rounded-md bg-[#223b8b] tracking-widest cursor-pointer px-5 py-1`}
                                                    buttonType='submit'
                                                    disabled={!retryOrderLoading}
                                                />
                                            </div>
                                        }

                                    </div>
                                </div>
                            )
                        }) : <div>No Data Found</div>}
                    </div>}
                </div>

            </div>
        </div>
    )
}

export default OrderView
