import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux'
import { PASSWORD_VALIDATE } from '../../helper/constant';
import AuthActions from '../../redux/auth/action';
import countryAction from '../../redux/country/actions';
import Button from '../../components/utils/Button';
import Alert from '../../components/utils/Alert';
import MohImage from './../../assets/images/moh-logo-blue-(1).png'
import SignupCartoon from './../../assets/images/signup-img-3d.png';




const { userSignUp, authReset } = AuthActions;
const { getCountry } = countryAction;

const Signup = () => {
  const dispatch = useDispatch();
  const countryResult = useSelector((state) => state.country.countryResult);
  const { signUpLoading, signUpMessage, signUpError } = useSelector((state) => state.auth);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);


  const [signUpData, setSignUpData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    mobileNumber: '',
    addressLine1: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    // promotionalOffer: ''
  })
  const { register, handleSubmit, watch, formState: { errors }, reset, control } = useForm({
    defaultValues: signUpData
  });
  const password = watch('password', '');

  useEffect(() => {
    dispatch(getCountry())
  }, [])

  useEffect(() => {
    if (signUpError !== null && signUpError !== undefined) {
      displayAlert(signUpError, 'alert-danger');
    }
    if (signUpMessage !== null && signUpMessage !== undefined) {
      displayAlert(signUpMessage, 'alert-success');
      reset()
    }
  }, [signUpError, signUpMessage]);


  const handleSignUp = (data) => {
    dispatch(userSignUp(data))
  }

  const displayAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
    dispatch(authReset());
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
    dispatch(authReset());
  }

  const handleNumber = (e) => {
    const { value } = e.target;
    if (!(e.shiftKey || (e.keyCode >= 48 && e.keyCode <= 57) || (e.keyCode >= 96 && e.keyCode <= 105) || e.keyCode === 8 || e.keyCode === 46)) {
      e.preventDefault();
    }
    if (value.includes('.')) {
      e.preventDefault();
    }
  };

  return (
    <div className='moh-conect-bg from-[#0f172a] to-[#0f172a]'>
      <div className="flex min-h-full  flex-1 flex-col justify-center px-6 py-12 lg:px-8">

        <div class="w-8/12 container md:mx-auto">
          <div class="grid grid-cols-1 gap-1">
            <div className='flex w-full bg-white login-moh-main'>

              <div className='w-[45%] bg-[#223b8b] rounded-l-lg-moh'>
                <div className='mt-[1.5rem] h-16'>
                  <div class="ribbon">
                    <span class="ribbon3">
                      <a href='/'><img src={MohImage} alt="mockup" width={150} className='mx-auto ' /></a>
                    </span>
                  </div>
                </div>
                <div className='mt-[3.7rem]'><img src={SignupCartoon} alt="mockup" width={340} className='mx-auto' /></div>

              </div>
              <div className='w-[55%]'>
                <div className="mt-5 sm:mx-auto px-5">
                  <h2 className="mt-[0.8rem] mb-[0.8rem] text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Sign Up to your account
                  </h2>
                  <div className="mb-2">
                    {showAlert && <div className='mt-2'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
                  </div>
                  <form className="space-y-2 mt-4" action="#" method="POST" onSubmit={handleSubmit(handleSignUp)}>
                    <div class="grid grid-cols-2 gap-2">
                      <div>
                        <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900"> Name </label>
                        <div className="mt-1">
                          <input id="name" name="name" type="text" autoComplete="name" {...register('name', {
                            required: true
                          })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                          {errors.name && errors.name.type === "required" != '' ? <div className="text-red-500 text-left text-xs">Name is required!</div> : ''}
                        </div>
                      </div>

                      <div>
                        <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900"> Email Address </label>
                        <div className="mt-1">
                          <input id="email" name="email" type="text" autoComplete="email" {...register('email', {
                            required: true,
                            pattern: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/
                          })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                          {errors.email && errors.email.type === "required" != '' ? <div className="text-red-500 text-left text-xs">Email address is required!</div> : ''}
                          {errors.email && errors.email.type === "pattern" != '' ? <div className="text-red-500 text-left text-xs">Invalid Email Formats!</div> : ''}

                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-2">
                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                            Password
                          </label>
                        </div>
                        <div className="mt-1 relative">
                          <input
                            id="password"
                            name="password"
                            type={passwordShow ? 'text' : 'password'}
                            autoComplete="current-password"
                            {...register('password', {
                              required: true,
                              minLength: 4,
                              validate: (value) => PASSWORD_VALIDATE.test(value),
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                          {errors.password && errors.password.type === "required" && (
                            <div className="text-red-500 text-left text-xs">Password is required!</div>
                          )}
                          {errors.password && errors.password.type === "minLength" && (
                            <div className="text-red-500 text-left text-xs">Password must be at least 4 characters long!</div>
                          )}
                          {errors.password && errors.password.type === "validate" && (
                            <div className="text-red-500 text-left text-xs">Invalid password format (Example@123)!</div>
                          )}
                          <div
                            onClick={() => setPasswordShow((pre) => !pre)}
                            className="signUpEyeIcon cursor-pointer"
                          >
                            <i className={passwordShow ? "ri-eye-line" : "ri-eye-off-line"}></i>
                          </div>
                        </div>
                      </div>

                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                            Confirm Password
                          </label>
                        </div>
                        <div className="mt-1 relative">
                          <input
                            id="confirmPassword"
                            name="confirmPassword"
                            type={confirmPasswordShow ? 'text' : 'password'}
                            autoComplete="confirm-password"
                            {...register('confirmPassword', {
                              required: true,
                              validate: (value) => value === password,
                            })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                          {errors.confirmPassword && errors.confirmPassword.type === "required" && (
                            <div className="text-red-500 text-left text-xs">Confirm Password is required!</div>
                          )}
                          {errors.confirmPassword && errors.confirmPassword.type === "validate" && (
                            <div className="text-red-500 text-left text-xs">Confirm Password does not match!</div>
                          )}
                          <div
                            onClick={() => setConfirmPasswordShow((pre) => !pre)}
                            className="signUpEyeIcon cursor-pointer"
                          >
                            <i className={confirmPasswordShow ? "ri-eye-line" : "ri-eye-off-line"}></i>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className='grid grid-cols-2 gap-2'>
                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="mobileNumber" className="block text-sm font-medium leading-6 text-gray-900">
                            Phone Number
                          </label>
                        </div>
                        <div className="mt-1">
                          <input id="mobileNumber" name="mobileNumber" type="number" maxLength={10} autoComplete="mobileNumber" {...register('mobileNumber', {
                            required: true,
                            pattern: { value: /^(0|[1-9]\d*)(\.\d+)?$/ }
                          })} onKeyDown={handleNumber} onInput={(e) => {
                            if (e.target.value.length > e.target.maxLength) e.target.value = e.target.value.slice(0, e.target.maxLength)
                          }}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                          {errors && errors.mobileNumber && (<div className="invalid-feedback">{errors.mobileNumber.message}</div>)}
                          {errors.mobileNumber && errors.mobileNumber.type === "required" != '' ? <div className="text-red-500 text-left text-xs">Phone Number is required!</div> : ''}
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="addressLine1" className="block text-sm font-medium leading-6 text-gray-900">
                            Address Line
                          </label>
                        </div>
                        <div className="mt-1">
                          <input id="addressLine1" name="addressLine1" type="text" autoComplete="addressLine1" {...register('addressLine1', {
                          })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-2 gap-2'>
                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="city" className="block text-sm font-medium leading-6 text-gray-900">
                            City
                          </label>
                        </div>
                        <div className="mt-1">
                          <input id="city" name="city" type="text" autoComplete="city" {...register('city', {
                          })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                        </div>
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="state" className="block text-sm font-medium leading-6 text-gray-900">
                            State
                          </label>
                        </div>
                        <div className="mt-1">
                          <input id="state" name="state" type="text" autoComplete="state" {...register('state', {
                          })}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                        </div>
                      </div>
                    </div>

                    <div className='grid grid-cols-2 gap-2'>
                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="country" className="block text-sm font-medium leading-6 text-gray-900">
                            Select Country
                          </label>
                        </div>
                        <select {...register('country', {
                          required: true
                        })} id="country" class="block w-full mt-1 p-2  text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500">
                          <option selected>Select Country</option>
                          {countryResult && countryResult.length > 0 && countryResult.map(country => (
                            <option value={country.ID} key={country.ID}>{country.CountryName}</option>
                          ))}
                        </select>
                      </div>
                      <div>
                        <div className="flex items-center justify-between">
                          <label htmlFor="zipcode" className="block text-sm font-medium leading-6 text-gray-900">
                            Postal Code
                          </label>
                        </div>
                        <div className="mt-1">
                          <input id="zipcode" name="zipcode" type="text" autoComplete="zipcode" {...register("zipcode", { required: true, minLength: 4, })}
                            className="block w-full mb-5 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                          />
                        </div>
                      </div>
                    </div>


                    <div>
                      <Button title={'Sign Up'} loading={signUpLoading} buttonClass="flex w-full justify-center rounded-md bg-[#223b8b] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#223b8bda] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" buttonType='submit' />

                    </div>
                  </form>

                  <p className="mt-[0.8rem] mb-[0.8rem] text-center text-sm text-gray-500">
                    Already a member?
                    <NavLink to="/login" className="font-semibold leading-6 text-[#223b8b] hover:text-[#223b8bda]">
                      Log In
                    </NavLink>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>

    </div>

  )
}

export default Signup;