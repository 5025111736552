import React from 'react';
import notFoundImage from '../../assets/images/404.png'
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className="h-[100vh]">
            <div className="container flex items-center justify-center h-full">
                <div className="">
                    <img
                        src={notFoundImage}
                        className="img-fluid vert-move"
                        alt="notFoundImage"
                    />
                    <h2 className="text-center mb-2 text-4xl">Oops! This Page is Not Found.</h2>
                    <p className='text-center mb-4 text-lg'>The requested page dose not exist.</p>
                    <div className='text-center '>
                        <Link to="/" className={`inline-flex items-center px-4 py-2 rounded-lg bg-[#223b8b] text-white text-center  `}>
                            <i className="ri-home-4-line mr-2" />
                            <span> Back to Home</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFound