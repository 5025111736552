import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';

export const makeUtilityPayment = async (products, paymentId, uID) => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const body = {
        products: products,
        paymentId: paymentId,
        uID : uID
    }

    const response = await axios.post(process.env.REACT_APP_BASEURL + '/create-utility-checkout-session', body, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const session = response.data;
    const result = stripe.redirectToCheckout({
        sessionId: session.id
    });

}

export const makePayment = async (products, paymentId) => {
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    const body = {
        products: products,
        paymentId: paymentId
    }

    const response = await axios.post(process.env.REACT_APP_BASEURL + '/create-checkout-session', body, {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    const session = response.data;
    const result = stripe.redirectToCheckout({
        sessionId: session.id
    });

}