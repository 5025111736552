import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { addData, getList } from '../../helper/fackBackend_Helper';

function* getPlanListByNetworkResponse({ network }) {
    try {
        const response = yield call(getList, '/planByNetworkforWebsite/' + network);
        if (response.response.Status) {
            yield put(actions.getPlanByNetworkSuccess(response.response.Data));
        } else {
            yield put(actions.getPlanByNetworkFailed(response.response.Message));
        }

    } catch (error) {
        yield put(actions.getPlanByNetworkFailed(error));
    }
}

function* createOrderResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/create-order', data);
        if (response.Status) {
            yield put(actions.createOrderSuccess(response.Message, response.order));
        } else {
            yield put(actions.createOrderFailed(response.Message));
        }

    } catch (error) {
        yield put(actions.createOrderFailed(error.message));
    }
}

function* processOrderResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/process-order', data);
        if (response.Status) {
            yield put(actions.processOrderSuccess(response.Message));
        } else {
            yield put(actions.processOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.processOrderFailed(error.message));
    }
}

function* failedOrderResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/failed-order', data);
        if (response.Status) {
            yield put(actions.failedOrderSuccess(response.Message));
        } else {
            yield put(actions.failedOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.failedOrderFailed(error.message));
    }
}

function* completeOrderResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/recharge-order', data);
        if (response.Status) {
            yield put(actions.completeOrderSuccess(response.Message));
        } else {
            yield put(actions.completeOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.completeOrderFailed(error.message));
    }
}


export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_PLAN_BY_NETWORK, getPlanListByNetworkResponse),
        takeEvery(actions.CREATE_ORDER, createOrderResponse),
        takeEvery(actions.PROCESS_ORDER, processOrderResponse),
        takeEvery(actions.FAILED_ORDER, failedOrderResponse),
        takeEvery(actions.COMPLETE_ORDER, completeOrderResponse),
    ]);
}
