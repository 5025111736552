import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom';
import rechargeSvg from './../../assets/images/cloud-bg.png'
import Button from '../../components/utils/Button';
import { useDispatch, useSelector } from 'react-redux'
import { isAuthenticated } from '../../helper/commonFunction';
import HomeActions from '../../redux/home/action';
import UtilityActions from '../../redux/utility/action';
import { makeUtilityPayment } from '../../helper/stripe';
import LoginModal from '../../components/utils/LoginModal';
import NoImage from '../../assets/images/no_image.png';
import Loader from '../../components/utils/Loader';


const { verifyUtilityID } = HomeActions;
const { getUtilityCommission, createUtilityOrder, openLoginModal } = UtilityActions;


const checkField = (val) => (val != '' && val != null && val != undefined);
const BASE_URL = process.env.REACT_APP_BASEURL;


const PrepaidElectricityBill = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const {
        getUtilityCommissionList,
        createUtilityOrderLoading,
        createUtilityOrderMessage,
        createUtilityOrderData,
        showLoginModal } = useSelector((state) => state.utility);
    const { UtilitylogoUrl, getNetworkByCountryIDLoading } = useSelector((state) => state.home);
    const [meterNumber, setMeterNumber] = useState('');
    const [amount, setAmount] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [fee, setFee] = useState(0);
    const [currency, setCurrency] = useState('');

    useEffect(() => {
        dispatch(verifyUtilityID(id));
        dispatch(getUtilityCommission(id));
    }, [id])

    useEffect(() => {
        let feeElement = getUtilityCommissionList.filter((item) => {
            if (item.Min && item.Max && (amount >= item.Min && amount <= item.Max)) {
                return true;
            } else if (item.Min && item.Max === null && (amount >= item.Min)) {
                return true;
            }
        });

        setFee((feeElement.length > 0 && feeElement[0].Fee) ?? 0);
        setCurrency((feeElement.length > 0 && feeElement[0].CurrencyCode) ?? '');
    }, [amount]);

    useEffect(() => {
        if (createUtilityOrderData.OrderID && createUtilityOrderData.OrderNumber) {
            localStorage.setItem('payment', 1);
            const products = [
                {
                    name: createUtilityOrderData.UtilityName,
                    price: createUtilityOrderData.GrossAmount,
                    // image: `${BASE_URL}/images/network/${createUtilityOrderData.NetworkLogoURL}`,
                    quantity: 1
                }
            ]
            makeUtilityPayment(products, createUtilityOrderData.PaymentTxnID, id);
        }
    }, [createUtilityOrderMessage]);


    const handleAmountChange = (e) => {
        let lastElement = getUtilityCommissionList && getUtilityCommissionList.length > 0 && getUtilityCommissionList[getUtilityCommissionList.length - 1];
        let value = e.target.value;

        if (lastElement.Max !== null && value <= lastElement.Max) {
            // setAmount(Number(value));
            setAmount(value);
        }
    }

    const handleMeterNumberChange = (e) => {
        let value = e.target.value;

        if (value.length <= 12) {
            setMeterNumber(value);
        }
    }

    const handleMobileNumberChange = (e) => {
        let value = e.target.value;

        if (value.length <= 10) {
            setMobileNumber(value);
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if (isAuthenticated()) {
            let request = {
                GrossAmount: amount + fee,
                FeePaid: fee,
                NetAmount: amount,
                SenderMobile: mobileNumber,
                ConsumerNumber: meterNumber
            }
            dispatch(createUtilityOrder(id, request));

        } else {
            dispatch(openLoginModal());
            // window.location.href = `/login`;
        }
    };

    const allFieldValid = useMemo(() => {
        if (checkField(meterNumber) && checkField(mobileNumber) && checkField(amount)) {
            return true;
        } else {
            return false;
        }
    }, [meterNumber, mobileNumber, amount]);

    return (
        <>
            <div className='maindiv-elrctric'>
                {
                    getNetworkByCountryIDLoading ? <Loader /> :
                        <>
                            <section className="wrapper !bg-[#8ca0e12e]">
                                <div className="container mx-auto">
                                    <div className='pt-7' >
                                        <h1 className="pt-32 xl:text-[2.5rem] text-[calc(1.375rem_+_1.5vw)] !leading-[1.15] font-bold  xl:!mr-5 xl:mt-[-2.5rem] lg:mt-[-2.5rem] text-center" data-cue="slideInDown" data-group="page-title" data-delay="900" data-show="true" >

                                            {/* <img className='postPaidElectric'style={{margin: '0px -11px 19px 0px'}}  src={UtilitylogoUrl ? `${BASE_URL}/images/utilities/${UtilitylogoUrl}` : NoImage} alt={''} width={90} height={90}/>  */}
                                            Prepaid <br className="hidden md:block xl:hidden lg:hidden" /><span className="text-[#1d89ca] ">Electricity Bill</span></h1>
                                    </div>
                                </div>
                                <figure><img className="w-full max-w-full !h-auto" src={rechargeSvg} alt="image" /></figure>
                            </section>

                            <section id="snippet-2" className="wrapper !bg-[#f5f7fa]  border-b-[rgba(164,174,198,0.2)]  px-32">
                                <form onSubmit={onSubmit}>
                                    <div className='w-[450px] mx-auto p-2 form-sec-main'>
                                        <div className="bg-white shadow-md rounded px-6 pb-4 payrechar-d">
                                            <div className='flex items-center justify-between'>
                                                <div><h1 className="text-start text-lg font-[600] mb-4">Enter details</h1></div>
                                                <div> <img className='postPaidElectric' style={{ margin: '-19px 0 0px 0' }} src={UtilitylogoUrl ? `${BASE_URL}/images/utilities/${UtilitylogoUrl}` : NoImage} alt={''} width={90} height={90} /> </div>
                                            </div>


                                            <div className="grid grid-cols-2 gap-4">
                                                <div className="flex flex-col col-span-full">
                                                    {/* <h2 className="block mb-2 text-md font-medium text-gray-900 input-label ">Meter Number</h2> */}
                                                    <div className="relative w-full input-container">
                                                        <input
                                                            type='number'
                                                            value={meterNumber}
                                                            placeholder=''
                                                            className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none input-field  "
                                                            onChange={(e) => handleMeterNumberChange(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                                        />
                                                        <label for="input" class="label">Meter Number</label>
                                                        <div class="underline"></div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col">

                                                    <div className="relative w-full input-container">
                                                        <input
                                                            type='number'
                                                            placeholder=''
                                                            value={amount}
                                                            className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none input-field"
                                                            onChange={(e) => handleAmountChange(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}
                                                        />
                                                        <label for="input" class="label">Amount</label>
                                                        <div class="underline"></div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col">

                                                    <div className="relative w-full input-container-fee">
                                                        <label for="input" class="label-1">Fee</label>
                                                        <input
                                                            type='text'
                                                            readOnly
                                                            placeholder=''
                                                            value={fee ? `${fee} ${currency == '$' ? currency : currency.toUpperCase()}` : ''}
                                                            className="rapido_fee block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none"
                                                        />

                                                        <div class=""></div>
                                                    </div>
                                                </div>

                                                <div className="flex flex-col col-span-full">

                                                    <div className="relative w-full input-container">
                                                        <input
                                                            type='number'
                                                            value={mobileNumber}
                                                            placeholder=''
                                                            className="block w-full p-2 text-gray-900 border rounded bg-gray-50 text-xs focus:outline-none input-field"
                                                            onChange={(e) => handleMobileNumberChange(e)}
                                                            onWheel={(e) => e.target.blur()}
                                                            onKeyDown={(e) => ['ArrowUp', 'ArrowDown'].includes(e.key) && e.preventDefault()}

                                                        />
                                                        <label for="input" class="label">Mobile Number</label>
                                                        <div class="underline"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <Button
                                                title={'Proceed'}
                                                buttonClass={`mx-auto w-full mt-[20px] text-lg flex align-center justify-center relative font-medium text-white transition duration-300 rounded-md ${allFieldValid ? 'bg-[#223b8be6] hover:bg-[#223b8b]' : 'bg-[#95a5a6]'} ease btn-padd-n cursor-pointer`}
                                                buttonType={'submit'}
                                                disable={!allFieldValid}
                                                loading={createUtilityOrderLoading}
                                            />

                                        </div>
                                    </div>
                                </form>
                            </section>
                            {showLoginModal ? <LoginModal /> : ''}
                        </>
                }
            </div>
        </>
    )
}

export default PrepaidElectricityBill