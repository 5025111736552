import { all } from 'redux-saga/effects';
import homeSagas from './home/saga';
import rechargeSagas from './recharge/saga';
import authSagas from './auth/saga'
import countrySagas from './country/saga'
import profileSagas from './profile/saga'
import contactSagas from './contactUs/saga';
import UtilitySagas from './utility/saga';

export default function* rootSaga() {
    yield all([
        homeSagas(),
        rechargeSagas(),
        authSagas(),
        countrySagas(),
        profileSagas(),
        contactSagas(),
        UtilitySagas(),
    ])
}