const actions = {
    GET_PLAN_BY_NETWORK: "GET_PLAN_BY_NETWORK",
    GET_PLAN_BY_NETWORK_SUCCESS: "GET_PLAN_BY_NETWORK_SUCCESS",
    GET_PLAN_BY_NETWORK_FAILED: "GET_PLAN_BY_NETWORK_FAILED",
    CREATE_ORDER: "CREATE_ORDER",
    CREATE_ORDER_SUCCESS: "CREATE_ORDER_SUCCESS",
    CREATE_ORDER_FAILED: "CREATE_ORDER_FAILED",
    RESET_RECHARGE: "RESET_RECHARGE",
    PROCESS_ORDER: "PROCESS_ORDER",
    PROCESS_ORDER_SUCCESS: "PROCESS_ORDER_SUCCESS",
    PROCESS_ORDER_FAILED: "PROCESS_ORDER_FAILED",
    FAILED_ORDER: "FAILED_ORDER",
    FAILED_ORDER_SUCCESS: "FAILED_ORDER_SUCCESS",
    FAILED_ORDER_FAILED: "FAILED_ORDER_FAILED",
    COMPLETE_ORDER: "COMPLETE_ORDER",
    COMPLETE_ORDER_SUCCESS: "COMPLETE_ORDER_SUCCESS",
    COMPLETE_ORDER_FAILED: "COMPLETE_ORDER_FAILED",
    SELECTED_NETWORK: "SELECTED_NETWORK",

    selectedNetwork: (selectedId) => ({
        type: actions.SELECTED_NETWORK,
        selectedId
    }),

    completeOrder: (data) => ({
        type: actions.COMPLETE_ORDER,
        payload: { data }
    }),
    completeOrderSuccess: (message) => ({
        type: actions.COMPLETE_ORDER_SUCCESS,
        payload: { message }
    }),
    completeOrderFailed: (error) => ({
        type: actions.COMPLETE_ORDER_FAILED,
        payload: { error }
    }),

    failedOrder: (data) => ({
        type: actions.FAILED_ORDER,
        payload: { data }
    }),
    failedOrderSuccess: (message) => ({
        type: actions.FAILED_ORDER_SUCCESS,
        payload: { message }
    }),
    failedOrderFailed: (error) => ({
        type: actions.FAILED_ORDER_FAILED,
        payload: { error }
    }),

    processOrder: (data) => ({
        type: actions.PROCESS_ORDER,
        payload: { data }
    }),
    processOrderSuccess: (message) => ({
        type: actions.PROCESS_ORDER_SUCCESS,
        payload: { message }
    }),
    processOrderFailed: (error) => ({
        type: actions.PROCESS_ORDER_FAILED,
        payload: { error }
    }),

    resetRecharge: () => ({
        type: actions.RESET_RECHARGE,
    }),

    createOrder: (data) => ({
        type: actions.CREATE_ORDER,
        payload: { data }
    }),
    createOrderSuccess: (message, data) => ({
        type: actions.CREATE_ORDER_SUCCESS,
        payload: { message, data }
    }),
    createOrderFailed: (error) => ({
        type: actions.CREATE_ORDER_FAILED,
        payload: { error }
    }),

    getPlanByNetwork: (network) => ({
        type: actions.GET_PLAN_BY_NETWORK,
        network
    }),
    getPlanByNetworkSuccess: (planResult) => ({
        type: actions.GET_PLAN_BY_NETWORK_SUCCESS,
        planResult
    }),
    getPlanByNetworkFailed: (planError) => ({
        type: actions.GET_PLAN_BY_NETWORK_FAILED,
        planError
    }),
};

export default actions;