import actions from "./action";

const initState = {
    signInToken: null,
    signInLoading: false,
    signInMessage: null,
    signInError: null,
    signUpLoading: false,
    signUpMessage: null,
    signUpError: null,
    loading: false,
    buttonLoading: false,
    forgotPasswordMesage: null,
    forgotPasswordError: null,
    verifyMessage: null,
    verifyFailed: null,
    verifyLoading: false,
    resetPasswordMessage:null,
    resetPasswordError:null,
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case actions.SIGN_IN:
            return {
                ...state,
                signInLoading: true,
                signUpMessage: null,
                signUpError: null,
            };
        case actions.SIGN_IN_SUCCESS:
            return {
                ...state,
                signInLoading: false,
                signInToken: action.token,
                signInMessage: 'Login success',
                signInError: null,
            };
        case actions.SIGN_IN_FAILED:
            return {
                ...state,
                signInLoading: false,
                signInMessage: null,
                signInError: action.error,
            };

        case actions.SIGN_UP:
            return {
                ...state,
                signUpLoading: true,
                signUpMessage: null,
                signUpError: null,
            };
        case actions.SIGN_UP_SUCCESS:
            return {
                ...state,
                signUpLoading: false,
                signUpMessage: action.message,
                signUpError: null,
            };
        case actions.SIGN_UP_FAILED:
            return {
                ...state,
                signUpLoading: false,
                signUpMessage: null,
                signUpError: action.error,
            };

        case actions.GOOGLE_AUTH:
            return {
                ...state,
                loading: true,
                signUpMessage: null,
                signUpError: null,
            };
        case actions.GOOGLE_AUTH_SUCCESS:
            return {
                ...state,
                loading: false,
                signInToken: action.token,
                signInMessage: 'Login success',
                signInError: null,
            };
        case actions.GOOGLE_AUTH_FAILED:
            return {
                ...state,
                loading: false,
                signInMessage: null,
                signInError: action.error,
            };

        case actions.AUTH_RESET:
            return {
                ...state,
                signUpLoading: false,
                signUpMessage: null,
                signUpError: null,
                signInLoading: false,
                signInMessage: null,
                signInError: null,
            };
        case actions.SIGN_OUT:
            return {
                ...state,
                buttonLoading: true,
            };
        case actions.SIGN_OUT_SUCCESS:
            return {
                ...state,
                buttonLoading: false,
            };
        case actions.SIGN_OUT_FAILED:
            return {
                ...state,
                error: action.payload.error,
                buttonLoading: false,
            };

        case actions.FORGOT_PASSWORD:
            return {
                ...state,
                loading: true,
                forgotMesage: null,
                forgotError: null,
            };
        case actions.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                forgotPasswordMesage: action.message,
                forgotPasswordError: null,
            };
        case actions.FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                forgotPasswordMesage: null,
                forgotPasswordError: action.error,
            };

        case actions.VERIFY_EMAIL:
            return {
                ...state,
                verifyLoading: true,
                verifyMessage: null,
                verifyFailed: null,
            };
        case actions.VERIFY_EMAIL_SUCCESS:
            return {
                ...state,
                verifyLoading: false,
                verifyMessage: action.message,
                verifyFailed: null,
            };
        case actions.VERIFY_EMAIL_FAILED:
            return {
                ...state,
                verifyLoading: false,
                verifyMessage: action.error,
                verifyFailed: null,
            };

        case actions.RESET_PASSWORD:
            return {
                ...state,
                loading: true,
                resetPasswordMessage: null,
                resetPasswordError: null,
            };
        case actions.RESET_PASSWORD_SUCCESS:

            return {
                ...state,
                loading: false,
                resetPasswordMessage: action.message,
                resetPasswordError: null,
            };
        case actions.RESET_PASSWORD_FAILED:

            return {
                ...state,
                loading: false,
                resetPasswordMessage: null,
                resetPasswordError: action.error,
            };


        default:
            return state;
    }
}