import React from 'react'
function Alert({ type, text, closeBtn, width }) {
    let closeButtonColor;
    let bgColor;
    if (type === 'alert-success') {
        closeButtonColor = '#00b894';
        bgColor='#F0FDF4';
    } else if (type === 'alert-danger') {
        closeButtonColor = '#d63031';
        bgColor='#fef2f2';
    } else if (type === 'alert-warning') {
        closeButtonColor = '#fdcb6e';
        bgColor=' #FEFCE8';
    } else {
        closeButtonColor = '#2d3436';
    }
    return (
        <>
            <div style={{color:closeButtonColor, border:`1px solid ${closeButtonColor}`, backgroundColor:`${bgColor}`}} className={`p-4 mt-4 text-sm border rounded-lg`} role="alert">
                {type === 'alert-success' ? <i class="ri-checkbox-circle-line text-md"></i> : ''}
                {type === 'alert-danger' ? <i class="ri-close-circle-line text-md"></i> : ''}
                {type === 'alert-warning' ? <i class="ri-error-warning-line text-md"></i> : ''}
                &nbsp;{text}
                <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={closeBtn}>
                    <i class="ri-close-line" style={{ color: closeButtonColor, width: width }}></i>
                </button>
            </div>
        </>

    )
}

export default Alert