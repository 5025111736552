import { combineReducers } from "redux";
import home from './home/reducer';
import recharge from './recharge/reducer'
import auth from './auth/reducer'
import country from './country/reducer'
import profile from './profile/reducer'
import contact from './contactUs/reducer';
import utility from "./utility/reducer"

const rootReducer = combineReducers({
    home,
    recharge,
    auth,
    country,
    profile,
    contact,
    utility,
});
export default rootReducer;