import React, { useEffect, useRef, useMemo, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import logoImage from './../../assets/images/moh-logo-blue-(1).png'
import { isAuthenticated } from '../../helper/commonFunction';
import ReactCountryFlag from "react-country-flag";
import countryAction from '../../redux/country/actions';
import { useDispatch, useSelector } from 'react-redux';
import HomeActions from '../../redux/home/action';
import RechargeActions from '../../redux/recharge/actions'


const { getCountryForWebsite } = countryAction;
const { getNetworksByCountryID, getServices } = HomeActions;
const { resetRecharge } = RechargeActions;

const DEFAULT_COUNTRY_ID = 233;

const Header = () => {
    const ref = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    let token = localStorage.getItem('token') ? localStorage.getItem('token') : null;
    let localStorageCountryID = localStorage.getItem('country_id') ? localStorage.getItem('country_id') : null;
    const countries = useSelector((state) => state.country.countryResultWebsite);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false)
    const getFlagIso2 = (iso2) => iso2.toUpperCase();
    // const defaultCountry = {
    //     ID: 233,
    //     ISO2: 'US',
    //     CountryName: 'United States'
    // };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!ref?.current?.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
    }, [ref]);


    const selectedCountry = useMemo(() => {
        let selected;
        if (localStorageCountryID) {
            selected = countries.filter((item) => item.ID == localStorageCountryID);
        } else {
            selected = countries.filter((item) => item.ID == DEFAULT_COUNTRY_ID);
        }
        return selected[0];
    }, [localStorageCountryID, countries, DEFAULT_COUNTRY_ID]);


    useEffect(() => {
        isAuthenticated();
        if (localStorageCountryID === null || localStorageCountryID === undefined) {
            localStorage.setItem('country_id', DEFAULT_COUNTRY_ID);
            dispatch(getCountryForWebsite(DEFAULT_COUNTRY_ID));
            dispatch(getNetworksByCountryID(DEFAULT_COUNTRY_ID));
            dispatch(getServices(DEFAULT_COUNTRY_ID));
        } else {
            dispatch(getCountryForWebsite(localStorageCountryID));
            dispatch(getNetworksByCountryID(localStorageCountryID));
            dispatch(getServices(localStorageCountryID));
        }

    }, [])

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleCountrySelect = (country) => {
        localStorage.setItem('country_id', country.ID);
        setIsDropdownOpen(false);
        dispatch(getNetworksByCountryID(country.ID));
        dispatch(getServices(country.ID));
        dispatch(resetRecharge());
        if (location.pathname !== '/') {
            navigate("/");
        }
    };

    return (
        <header>
            <nav id="navbar" className={`fixed inset-x-0 z-20 w-full border-b border-gray-100 bg-white backdrop-blur ${isMenuOpen ? 'show' : ''}`}>
                <div className="mx-auto px-4 sm:px-12 xl:max-w-6xl xl:px-0">
                    <div className="relative flex flex-wrap items-center justify-between gap-6 lg:gap-0 lg:py-2">
                        <div className="relative z-20 flex w-full justify-between md:px-0 lg:w-max">
                            <div onClick={toggleMenu}>
                                <NavLink to="/" aria-label="ampire logo" className="nav-link flex items-center space-x-2">
                                    <img src={logoImage} alt="mockup" width={180} />
                                </NavLink>
                            </div>

                            <button aria-label="humburger" id="hamburger" className="relative -mr-6 p-6 lg:hidden" onClick={toggleMenu}>
                                <div aria-hidden="true" id="line" className="m-auto h-0.5 w-5 rounded bg-sky-900 transition duration-300"></div>
                                <div aria-hidden="true" id="line2" className="m-auto mt-2 h-0.5 w-5 rounded bg-sky-900 transition duration-300"></div>
                            </button>
                        </div>
                        <div aria-hidden="true" id="layer" className="fixed inset-0 z-10 h-screen w-screen origin-bottom scale-y-0 bg-white/70 backdrop-blur-2xl transition duration-500 lg:hidden"></div>
                        <div id="navlinks" className={`invisible absolute top-full left-0 z-20 w-full origin-top-right translate-y-1 scale-90 flex-col flex-wrap justify-end gap-6 rounded-3xl border border-gray-100 bg-white p-8 opacity-0 shadow-2xl shadow-gray-600/10 transition-all duration-300 lg:visible lg:relative lg:flex lg:w-auto lg:translate-y-0 lg:scale-100 lg:flex-row lg:items-center lg:gap-0 lg:border-none lg:bg-transparent lg:p-0 lg:opacity-100 lg:shadow-none`}>
                            <div className={`text-gray-600 lg:pr-4`}>
                                <ul className="space-y-6 text-base font-medium tracking-wide lg:flex lg:space-y-0 lg:text-sm">
                                    <li className={`act-home ul-txt`} onClick={toggleMenu}>
                                        <NavLink to="/" className=" nav-color nav-link block transition hover:text-primary lg:px-4" activeclassname="active">
                                            <span className='active'>Home</span>
                                        </NavLink>
                                    </li>
                                    <li className='ul-txt' onClick={toggleMenu}>
                                        <NavLink to="/contact-us" className="nav-color nav-link block transition hover:text-primary lg:px-4" activeclassname="active">
                                            <span>Contact us</span>
                                        </NavLink>
                                    </li>
                                    {token === '' || token === null ? "" : <li onClick={toggleMenu}>
                                        <NavLink to="/profile" className="nav-color nav-link block transition hover:text-primary lg:px-4  bg-[#cae5f6a3]  hover:bg-[#1d89ca4d] text-[#1d89ca] font-bold py-2 px-4 rounded inline-flex items-center" activeclassname="active">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle w-4 h-4 mr-2" viewBox="0 0 16 16">
                                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1" />
                                            </svg>
                                            <span>Profile</span>
                                        </NavLink>
                                    </li>}
                                </ul>
                                <i class="active-marker"></i>
                            </div>
                            <div className="relative" ref={ref}>
                                <button onClick={toggleDropdown} className="flex items-center space-x-2 p-2 border rounded-lg bg-white hover:bg-gray-100 h-[40px]">
                                    {selectedCountry ? (
                                        <ReactCountryFlag countryCode={getFlagIso2(selectedCountry.ISO2)} svg style={{ width: '29px', height: '29px' }} />
                                    ) : (
                                        <span>Select Country</span>
                                    )}
                                    <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                                    </svg>
                                </button>
                                {isDropdownOpen && (
                                    <div className="absolute mt-2 bg-white border rounded-lg shadow-lg w-[14rem] right-0">
                                        <div className="grid grid-cols-2 gap-1 p-1">
                                            {countries.map((country) => (
                                                <div
                                                    key={country.ISO2}
                                                    className="cursor-pointer flex flex-col items-center p-2 hover:bg-gray-200 rounded-lg transition duration-150 ease-in-out "
                                                    onClick={() => handleCountrySelect(country)}
                                                >
                                                    <ReactCountryFlag countryCode={getFlagIso2(country.ISO2)} svg style={{ width: '25px', height: '25px' }} />
                                                    <span className="text-sm mt-1">{country.CountryName}</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className='flex'>
                                {token === '' || token === null ? <div onClick={toggleMenu} className="mt-12 -ml-1 flex w-full flex-col space-y-2 border-primary/10 sm:flex-row md:w-max lg:mt-0 lg:mr-6 lg:space-y-0 lg:border-l lg:pl-6">
                                    <NavLink to="/login" className="rounded px-5 py-[5px] overflow-hidden group bg-[#223b8b] relative hover:bg-gradient-to-r hover:from-[#0887c9] hover:to-[#0887c9] text-white  hover:ring-offset-2  transition-all ease-out duration-300">
                                        <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                                        <span class="relative">Login </span>
                                    </NavLink>
                                </div> : ''}
                                {token === '' || token === null ? <div onClick={toggleMenu} className="mt-12 -ml-1 flex w-full flex-col space-y-2 border-primary/10 sm:flex-row md:w-max lg:mt-0 lg:mr-6 lg:space-y-0 lg:border-l lg:pl-6">
                                    <NavLink to="/sign-up" className="rounded px-5 py-[5px] overflow-hidden group bg-[#223b8b] relative hover:bg-gradient-to-r hover:from-[#0887c9] hover:to-[#0887c9] text-white  hover:ring-offset-2  transition-all ease-out duration-300">
                                        <span class="absolute right-0 w-8 h-32 -mt-12 transition-all duration-1000 transform translate-x-12 bg-white opacity-10 rotate-12 group-hover:-translate-x-40 ease"></span>
                                        <span class="relative">Sign up</span>
                                    </NavLink>
                                </div> : ''}
                            </div>
                        </div>
                    </div>
                </div>

            </nav>
        </header>
    )
}

export default Header