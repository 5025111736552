import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import AuthActions from './../../redux/auth/action';
import { useDispatch, useSelector } from 'react-redux';
import Alert from '../../components/utils/Alert';
import Button from '../../components/utils/Button';
import GoogleAuth from '../../components/layout/GoogleAuth';
import FacebookAuth from '../../components/layout/FacebookAuth';
import MohImage from './../../assets/images/moh-logo-blue-(1).png'
import LoginCartoon from './../../assets/images/login-3dimg.png'

const { signIn, authReset } = AuthActions;

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [passwordShow, setPasswordShow] = useState(false);
  const { signInToken, signInLoading, signInMessage, signInError } = useSelector((state) => state.auth);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');


  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  })

  useEffect(() => {
    if (signInError !== null && signInError !== undefined) {
      displayAlert(signInError, 'alert-danger');
    }
    if (signInMessage !== null && signInMessage !== undefined) {
      displayAlert(signInMessage, 'alert-success');
    }
  }, [signInError, signInMessage]);

  useEffect(() => {
    if (signInMessage) {
      localStorage.setItem("token", JSON.stringify(signInToken))
      window.location.reload();;
    }
  }, [signInMessage])


  const handleEmailChange = (e) => {
    const value = e.target.value
    setLoginData(pre => ({
      ...pre,
      email: value
    }))
  }

  const handlePasswordChange = (e) => {
    const value = e.target.value
    setLoginData(pre => ({
      ...pre,
      password: value
    }))
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(signIn(loginData))
  }

  const displayAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
    dispatch(authReset());
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
    dispatch(authReset());
  }

  return (
    <div className='moh-conect-bg from-[#0f172a] to-[#0f172a]'>
      <div className="flex min-h-full  flex-1 flex-col justify-center px-6 py-12 lg:px-8">

        <div class="w-8/12 container md:mx-auto ">
          <div class="grid grid-cols-1 gap-1">
            <div className='flex w-full bg-white login-moh-main'>
              <div className='w-[45%] bg-[#223b8b] rounded-l-lg-moh'>

                <div className='mt-[1.5rem] h-16'>
                  <div class="ribbon">
                    <span class="ribbon3">
                      <a href='/'><img src={MohImage} alt="mockup" width={150} className='mx-auto ' /></a>
                    </span>
                  </div>
                </div>
                <div className='mt-[1.7rem]'><img src={LoginCartoon} alt="mockup" width={340} className='mx-auto' /></div>
              </div>

              <div className='w-[55%]'>
                <div className="mt-5 mb-5 sm:mx-auto sm:w-full sm:max-w-sm">
                  <h2 className="mt-[1.5rem] mb-[0.9rem] text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    Login to your account
                  </h2>
                  <div className="sm:mx-auto sm:w-full sm:max-w-sm mt-[0px]">
                    {showAlert && <div className='mb-[15px]'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
                  </div>
                  <form
                    className="space-y-6"
                    action="#" method="POST"
                  >
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                        Email address
                      </label>
                      <div className="mt-2">
                        <input
                          onChange={(e) => handleEmailChange(e)}
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          value={loginData.email}
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                        />
                      </div>
                    </div>

                    <div>
                      <div className="flex items-center justify-between">

                        <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                          Password
                        </label>
                        <div className="text-sm">
                          <a href="/forget-password" className="font-semibold text-[#223b8b] hover:text-[#223b8b]">
                            Forgot your password?
                          </a>
                        </div>
                      </div>
                      <div className="mt-2 relative">
                        <input
                          onChange={(e) => handlePasswordChange(e)}
                          id="password"
                          name="password"
                          type={passwordShow ? 'text' : 'password'}
                          autoComplete="current-password"
                          value={loginData.password}
                          required
                          className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                        />
                        {passwordShow ? <div onClick={() => setPasswordShow((pre) => !pre)} className="signUpEyeIcon cursor-pointer"><i class="ri-eye-line"></i></div> : <div className="signUpEyeIcon cursor-pointer" onClick={() => setPasswordShow((pre) => !pre)}><i class="ri-eye-off-line"></i></div>}

                      </div>
                    </div>

                    <div>
                      <Button title={'Sign In'} onClick={handleSubmit} loading={signInLoading} buttonClass="flex w-full justify-center rounded-md bg-[#223b8b] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#223b8b] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" buttonType='button' />


                    </div>
                  </form>

                  <div className="relative mb-4 mt-2 text-center">
                    <span>OR</span>
                    <hr className='mt-2' />
                  </div>

                  <div className="flex items-center justify-center gap-4 mb-3 text-center">
                    <GoogleAuth />
                    <FacebookAuth />
                  </div>

                  <p className="mt-2 text-center text-sm text-gray-500">
                    Don't have an account?
                    <NavLink to="/sign-up" className="font-semibold leading-6 text-[#223b8b] hover:text-[#223b8b]">
                      Sign Up
                    </NavLink>
                  </p>
                </div>
              </div>


            </div>

          </div>
        </div>



      </div>
    </div>
  )
}

export default Login
