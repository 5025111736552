import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./action";
import { getList, addData } from "../../helper/fackBackend_Helper";


function* getUtilityCommissionResponse({ payload: { uID } }) {
    try {
        const response = yield call(getList, "/utility-commission/" + uID);
        if (response.Status) {
            yield put(actions.getUtilityCommissionSuccess(response.Data));
        } else {
            yield put(actions.getUtilityCommissionFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.getUtilityCommissionFailed(error.message));
    }
}

function* searchUtilityDetailsResponse({ payload: { utilityId, data } }) {
    try {
        const response = yield call(addData, '/search-utility-bill-details/' + utilityId, data);
        if (response.status === "SUCCESSFUL") {
            yield put(actions.searchForUtilityDetailsSuccess(response));
        } else {
            yield put(actions.searchForUtilityDetailsFailed(response.message));
        }
    } catch (error) {
        yield put(actions.searchForUtilityDetailsFailed(error));
    }
}

function* createUtilityOrderResponse({ payload: { utilityId, data } }) {
    try {
        const response = yield call(addData, '/create-utility-order/' + utilityId, data);
        if (response.Status) {
            yield put(actions.createUtilityOrderSuccess(response.Message, response.Data));
        } else {
            yield put(actions.createUtilityOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.createUtilityOrderFailed(error.message));
    }
}

function* failedUtilityOrderResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/failed-utility-order', data);
        if (response.Status) {
            yield put(actions.failedUtilityOrderSuccess(response.Message));
        } else {
            yield put(actions.failedUtilityOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.failedUtilityOrderFailed(error.message));
    }
}

function* processUtilityOrderResponse({ payload: { data } }) {
    try {
        const response = yield call(addData, '/process-utility-order', data);
        if (response.Status) {
            yield put(actions.processUtilityOrderSuccess(response.Message, response.Data));
        } else {
            yield put(actions.processUtilityOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.processUtilityOrderFailed(error.message));
    }
}

function* completeUtilityOrderResponse({ payload: { data, utilityID, orderID } }) {
    try {
        const response = yield call(addData, `/complete-utility/${utilityID}/${orderID}`, data);
        if (response.Status) {
            yield put(actions.completeUtilityOrderSuccess(response.Message));
        } else {
            yield put(actions.completeUtilityOrderFailed(response.Message));
        }
    } catch (error) {
        yield put(actions.completeUtilityOrderFailed(error.message));
    }
}

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_UTILITY_COMMISSION, getUtilityCommissionResponse),
        takeEvery(actions.SEARCH_FOR_UTILITY_DETAILS, searchUtilityDetailsResponse),
        takeEvery(actions.CREATE_UTILITY_ORDER, createUtilityOrderResponse),
        takeEvery(actions.FAILED_UTILITY_ORDER, failedUtilityOrderResponse),
        takeEvery(actions.PROCESS_UTILITY_ORDER, processUtilityOrderResponse),
        takeEvery(actions.COMPLETE_UTILITY_ORDER, completeUtilityOrderResponse),
    ]);
}