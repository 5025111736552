import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ForgetPass from './../../assets/images/forget-icons.png'
import MohImage from './../../assets/images/moh-logo-blue-(1).png'
import Button from '../../components/utils/Button'
import AuthActions from '../../redux/auth/action'
import { useDispatch, useSelector } from "react-redux";
import Alert from '../../components/utils/Alert';

const { forgotPassword, authReset } = AuthActions;

const ForgetPassword = () => {
    const dispatch = useDispatch();
    const { loading, forgotPasswordMesage, forgotPasswordError } = useSelector((state) => state.auth);
    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const [showAlert, setShowAlert] = useState(false);
    const [alertType, setAlertType] = useState('');
    const [alertText, setAlertText] = useState('');

    const handleSignIn = (data) => {
        dispatch(forgotPassword(data));
    }

    const displayAlert = (text, type) => {
        setAlertText(text);
        setAlertType(type);
        setShowAlert(true);
        dispatch(authReset());
    };

    const alertCancel = () => {
        setShowAlert(false);
        setAlertText('');
        setAlertType('');
        dispatch(authReset());
    }

    useEffect(() => {
        if (forgotPasswordError !== null && forgotPasswordError !== undefined) {
            displayAlert(forgotPasswordError, 'alert-danger');
        }
        if (forgotPasswordMesage !== null && forgotPasswordMesage !== undefined) {
            displayAlert(forgotPasswordMesage, 'alert-success');
            reset();
        }
    }, [forgotPasswordError, forgotPasswordMesage]);

    return (
        <div className='moh-conect-bg from-[#0f172a] to-[#0f172a]'>
            <div className="flex min-h-full  flex-1 flex-col justify-center px-6 py-12 lg:px-8">

                <div class="w-8/12 container md:mx-auto">
                    <div class="grid grid-cols-1 gap-1 ">
                        <div className='flex w-full bg-white login-moh-main'>
                            <div className='w-[45%] bg-[#223b8b] rounded-l-lg-moh'>

                                <div className='mt-[1.5rem] h-16'>
                                    <div class="ribbon">
                                        <span class="ribbon3"><a href="/"><img src={MohImage} alt="mockup" width={150} className='mx-auto ' /></a></span>
                                    </div>
                                </div>
                                <div className='mt-[1rem]'><a href="/"><img src={ForgetPass} alt="mockup" width={210} className='mx-auto' /></a></div>
                            </div>
                            <div className='w-[60%]'>
                                {showAlert && <div className='mt-[35px] mb-[10px] w-[71%] mx-auto flex items-center justify-between'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
                                < div className="mt-5 mb-5 sm:mx-auto sm:w-full sm:max-w-sm ">
                                    <h2 className="mt-[1.5rem] mb-[0.9rem] text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                        Forgot Your Password?
                                    </h2>
                                    <p class="mb-4 xl:text-sm lg:text-sm md:text-sm sm:text-sm text-xs text-[#727272] leading-6">
                                        We get it, stuff happens. Just enter your email address below and we'll send you a
                                        link to reset your password!
                                    </p>
                                    <form onSubmit={handleSubmit(handleSignIn)}>
                                        <div className="flex flex-col">
                                            <div className="flex items-center justify-between">
                                                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                                                    Email
                                                </label>
                                            </div>
                                            <div className="mt-2">
                                                <input
                                                    type="text"
                                                    {...register('email', { required: true, pattern: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/ })}
                                                    // placeholder="Email"
                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                                                />
                                                {errors.email?.type === "required" && <div className="text-red-500 text-left text-xs">Email is required!</div>}
                                                {errors.email?.type === "pattern" && <div className="text-red-500 text-left text-xs">Email is not valid!</div>}
                                            </div>
                                        </div>

                                        <div className="mt-5">
                                            <Button title={'Submit'} loading={loading} buttonClass="flex w-full justify-center rounded-md bg-[#223b8b] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#223b8bda] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" buttonType='submit' />
                                        </div>
                                    </form>
                                    <div className="mt-5 text-center ">
                                        <div><span href="#">Back to login ? <a href='/login' className="text-[#662e91]"><u>Log In</u></a>  </span></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ForgetPassword
