import React from 'react'
import rechargeSvg from './../../assets/images/clouds.png'
const AboutUs = () => {
    return (
      <>
      <section className="wrapper !bg-[#8ca0e12e]">
                <div class="container mx-auto">
                    <div className='py-14' >
                        <h1 className="pt-32 xl:text-[2.5rem] text-[calc(1.375rem_+_1.5vw)] !leading-[1.15] font-bold  xl:!mr-5 xl:mt-[-2.5rem] lg:mt-[-2.5rem] text-center" data-cue="slideInDown" data-group="page-title" data-delay="900" data-show="true" >About <br className="hidden md:block xl:hidden lg:hidden" /><span className="text-[#1d89ca] ">Us</span></h1>
                    </div>
                </div>

                <figure className="m-0 p-0"><img className="w-full max-w-full !h-auto" src={rechargeSvg} alt="image" /></figure>
            </section>
            <section className='pb-16'>
            <div className="container mx-auto">
            <div className=' mx-auto w-3/4 bg- from-white rounded-md'>
                <h2 className='wlcm'>Welcome to MohConnect</h2>
                <p className='pb-5'>At MohConnect, we cover distances between you and your loved ones. Based in the heart of New York City, our aim is to make global connections much easier and more accessible. From mobile top-ups sent around the world to international bill pay, and online shopping for your family in Africa—all while sitting at home in the US—here, there are many services tailored to meet your needs.</p>
               <p>One of our services includes domestic and international mobile top-up. The service allows for the instant replenishment of mobile phones in the US and worldwide.
International Bill Payments: The ability to pay for utilities like electricity, water, and TV services in many countries around the world quickly. International Shopping: Shop for your family while in the US, and have the items delivered directly to their doorstep while in Africa.
At MohConnect, we believe in delivering quality and effective services that keep you connected to the people that matter most in your life. Each transaction is secure and seamless, which puts our team at ease with any service in use.
</p>
</div> </div> 
            </section>
      </>
    )
}

export default AboutUs