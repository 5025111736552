// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCxKfBB7d1nLrN_sYvjviYaiBVPoYgePbA",
  authDomain: "moh-connect-website.firebaseapp.com",
  projectId: "moh-connect-website",
  storageBucket: "moh-connect-website.appspot.com",
  messagingSenderId: "30578062812",
  appId: "1:30578062812:web:d1ba38caf9293a67638b19"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);