import actions from "./action";

const initState = {
    getUtilityCommissionLoading: false,
    getUtilityCommissionError: null,
    getUtilityCommissionMessage: null,
    getUtilityCommissionList: [],
    searchUtilityDetailsData: [],
    searchUtilityDetailsLoading: false,
    searchUtilityDetailsError: null,
    searchUtilityDetailsMessage: null,
    createUtilityOrderLoading: false,
    createUtilityOrderError: null,
    createUtilityOrderMessage: null,
    createUtilityOrderData: [],
    failedUtilityOrderLoading: false,
    failedUtilityOrderError: null,
    failedUtilityOrderMessage: null,
    showLoginModal: false,
    processUtilityOrderLoading: false,
    processUtilityOrderError: null,
    processUtilityOrderMessage: null,
    processUtilityOrderData: {},
    completeUtilityOrderLoading: false,
    completeUtilityOrderError: null,
    completeUtilityOrderMessage: null,
}

export default function reducer(state = initState, action) {
    switch (action.type) {

        case actions.COMPLETE_UTILITY_ORDER:
            return {
                ...state,
                completeUtilityOrderLoading: true,
                completeUtilityOrderError: null,
                completeUtilityOrderMessage: null,
            };
        case actions.COMPLETE_UTILITY_ORDER_SUCCESS:
            return {
                ...state,
                completeUtilityOrderLoading: false,
                completeUtilityOrderError: null,
                completeUtilityOrderMessage: action.payload.message,
            };
        case actions.COMPLETE_UTILITY_ORDER_FAILED:
            return {
                ...state,
                completeUtilityOrderLoading: false,
                completeUtilityOrderError: action.payload.error,
                completeUtilityOrderMessage: null,
            };

        case actions.PROCESS_UTILITY_ORDER:
            return {
                ...state,
                processUtilityOrderLoading: true,
                processUtilityOrderError: null,
                processUtilityOrderMessage: null,
            };
        case actions.PROCESS_UTILITY_ORDER_SUCCESS:
            return {
                ...state,
                processUtilityOrderLoading: false,
                processUtilityOrderError: null,
                processUtilityOrderMessage: action.payload.message,
                processUtilityOrderData: action.payload.data,
            };
        case actions.PROCESS_UTILITY_ORDER_FAILED:
            return {
                ...state,
                processUtilityOrderLoading: false,
                processUtilityOrderError: action.payload.error,
                processUtilityOrderMessage: null,
            };

        case actions.FAILED_UTILITY_ORDER:
            return {
                ...state,
                failedUtilityOrderLoading: true,
                failedUtilityOrderError: null,
                failedUtilityOrderMessage: null,
            };
        case actions.FAILED_UTILITY_ORDER_SUCCESS:
            return {
                ...state,
                failedUtilityOrderLoading: false,
                failedUtilityOrderError: null,
                failedUtilityOrderMessage: action.payload.message,
            };
        case actions.FAILED_UTILITY_ORDER_FAILED:
            return {
                ...state,
                failedUtilityOrderLoading: false,
                failedUtilityOrderError: action.payload.error,
                failedUtilityOrderMessage: null,
            };

        case actions.CREATE_UTILITY_ORDER:
            return {
                ...state,
                createUtilityOrderLoading: true,
                createUtilityOrderError: null,
                createUtilityOrderMessage: null,
            };
        case actions.CREATE_UTILITY_ORDER_SUCCESS:
            return {
                ...state,
                createUtilityOrderLoading: true,
                createUtilityOrderError: null,
                createUtilityOrderMessage: action.payload.message,
                createUtilityOrderData: action.payload.data,
            };
        case actions.CREATE_UTILITY_ORDER_FAILED:
            return {
                ...state,
                createUtilityOrderLoading: false,
                createUtilityOrderError: action.payload.error,
                createUtilityOrderMessage: null,
            };


        case actions.GET_UTILITY_COMMISSION:
            return {
                ...state,
                getUtilityCommissionLoading: true,
                getUtilityCommissionError: null,
                getUtilityCommissionMessage: null,
                getUtilityCommissionList: [],
                searchUtilityDetailsData: [],
            };
        case actions.GET_UTILITY_COMMISSION_SUCCESS:
            return {
                ...state,
                getUtilityCommissionLoading: false,
                getUtilityCommissionError: null,
                getUtilityCommissionMessage: null,
                getUtilityCommissionList: action.payload.data,
            };
        case actions.GET_UTILITY_COMMISSION_FAILED:
            return {
                ...state,
                getUtilityCommissionLoading: false,
                getUtilityCommissionError: null,
                getUtilityCommissionMessage: null,
            };

        case actions.SEARCH_FOR_UTILITY_DETAILS:
            return {
                ...state,
                searchUtilityDetailsData: [],
                searchUtilityDetailsError: null,
                searchUtilityDetailsMessage: null,
                searchUtilityDetailsLoading: true,
            };
        case actions.SEARCH_FOR_UTILITY_DETAILS_SUCCESS:
            return {
                ...state,
                searchUtilityDetailsData: action.payload.result.factures,
                searchUtilityDetailsLoading: false,
                searchUtilityDetailsError: null,
                searchUtilityDetailsMessage: action.payload.result.message,
            };
        case actions.SEARCH_FOR_UTILITY_DETAILS_FAILED:
            return {
                ...state,
                searchUtilityDetailsLoading: false,
                searchUtilityDetailsError: action.payload.error,
                searchUtilityDetailsMessage: null,
            };

        case actions.FORM_RESET:
            return {
                ...state,
                searchUtilityDetailsData: [],
                searchLoading: false,
                utilityDetailsError: null,
                utilityDetailsMessage: null,
                searchUtilityDetailsError:null,
                searchUtilityDetailsMessage: null,
            };

        case actions.OPEN_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: true,
            };
        case actions.CLOSE_LOGIN_MODAL:
            return {
                ...state,
                showLoginModal: false,
            };
        default:
            return state;
    }
}