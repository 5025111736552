// This is all common function used in all over the application
import { jwtDecode } from "jwt-decode";

// let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
let token = localStorage.getItem('token') ? localStorage.getItem('token') : null;

export const isAuthenticated = () => {
  try {
    // let token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    if (token) {
      let decodedToken = jwtDecode(token);
      let currentDate = new Date();
      if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem("token");
        return false;
      } else {
        return true;
      }
    } else {
      localStorage.removeItem("token");
      return false;
    }
  } catch (error) {
    localStorage.removeItem("token");
    window.location.href = '/';
    window.location.reload();
    console.log('error :::', error.message);
  }
}

export const twoDigitDecimal = (number) => {
  let twoDigitDecimal = parseFloat(number).toFixed(2);
  return twoDigitDecimal;
}



