import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useForm, Controller } from 'react-hook-form';
import { PASSWORD_VALIDATE } from '../../helper/constant';
import Button from '../../components/utils/Button';
import ProfileActions from "../../redux/profile/actions";
import Alert from '../../components/utils/Alert';

const { changeCustomerPassword, resetMessage } = ProfileActions;


const ChangePassword = () => {
  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const { passwordMessage, passwordError, passwordButtonLoading } = useSelector((state) => state.profile);
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('');
  const [alertText, setAlertText] = useState('');



  const [resetPasswordData, setResetPasswordData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  })

  const { register, handleSubmit, watch, formState: { errors }, reset, control } = useForm({
    defaultValues: resetPasswordData
  });
  const newPassword = watch('newPassword', '');

  useEffect(() => {
    if (passwordError !== null && passwordError !== undefined) {
      displayAlert(passwordError, 'alert-danger');
    }
    if (passwordMessage !== null && passwordMessage !== undefined) {
      displayAlert(passwordMessage, 'alert-success');
      reset()
    }
  }, [passwordError, passwordMessage]);

  const displayAlert = (text, type) => {
    setAlertText(text);
    setAlertType(type);
    setShowAlert(true);
    dispatch(resetMessage());
  };

  const alertCancel = () => {
    setShowAlert(false);
    setAlertText('');
    setAlertType('');
    dispatch(resetMessage());
  }

  const handleResetPassword = (data) => {
    dispatch(changeCustomerPassword(data))
  }
  return (
    <>
      <section className='flex justify-center mt-[6px]'>
        {showAlert && <div className='mb-[15px] w-1/2'><Alert type={alertType} text={alertText} closeBtn={alertCancel} /></div>}
      </section>

      <section className='pb-[3.5rem]'>
        <div className="container mx-auto">
          <div className=' mx-auto w-[87%] bg- from-white rounded-md shadow-lg pb-12'>
          <h2 className="mb-[.8rem] text-center text-2xl font-[600] leading-9 tracking-tight text-gray-900">
                  Change Passowrd
                </h2>
            <div className=" w-9/12 flex justify-center items-center mx-auto ">
            
              <div className=" h-auto  w-full  bg-[#fff]  shadow-md p-[20px]">
                


                <form className="space-y-2 " action="#" method="POST" onSubmit={handleSubmit(handleResetPassword)}>
                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <label htmlFor="oldPassword" className="block text-sm font-medium leading-6 text-gray-900">
                        Current Passowrd
                      </label>
                    </div>
                    <div className="mt-2">
                      <input id="oldPassword" name="oldPassword" type={passwordShow ? "text" : "password"} autoComplete="oldPassword" {...register('oldPassword', {
                        required: true,
                      })}
                        className="input_show block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                      />
                      <div className='password_icon mr-2'>
                        {passwordShow ? <i class="ri-eye-line" style={{ cursor: 'pointer' }} onClick={() => setPasswordShow((pre) => !pre)}></i> : <i class="ri-eye-off-line" onClick={() => setPasswordShow((pre) => !pre)} style={{ cursor: 'pointer' }}></i>}
                      </div>
                    </div>
                    {errors.oldPassword && errors.oldPassword.type === "required" != '' ? <div className="text-red-500 text-left text-xs">Current Passowrd is required!</div> : ''}
                  </div>

                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <label htmlFor="newPassword" className="block text-sm font-medium leading-6 text-gray-900">
                        New Passowrd
                      </label>
                    </div>
                    <div className="mt-2">
                      <input id="newPassword" name="newPassword" type={newPasswordShow ? "text" : "password"} autoComplete="newPassword" {...register('newPassword', {
                        required: true,
                        minLength: 4,
                        validate: (value) => {
                          return PASSWORD_VALIDATE.test(value)
                        }
                      })}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                      />
                      <div className='password_icon mr-2'>
                        {newPasswordShow ? <i class="ri-eye-line" style={{ cursor: 'pointer' }} onClick={() => setNewPasswordShow((pre) => !pre)}></i> : <i class="ri-eye-off-line" onClick={() => setNewPasswordShow((pre) => !pre)} style={{ cursor: 'pointer' }}></i>}
                      </div>
                      {errors.newPassword && errors.newPassword.type === "minLength" && (<div className="text-red-500 text-left text-xs">New password must be at least 4 characters long!</div>)}
                      {errors.newPassword && errors.newPassword.type === "validate" != '' ? <div className="text-red-500 text-left text-xs">Invalid password format (Example@123) !</div> : ''}
                      {errors.newPassword && errors.newPassword.type === "required" != '' ? <div className="text-red-500 text-left text-xs">New passowrd is required!</div> : ''}
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="flex items-center justify-between">
                      <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
                        Confirm Passowrd
                      </label>
                    </div>
                    <div className="mt-2">
                      <input id="confirmPassword" name="confirmPassword" type={confirmPasswordShow ? "text" : "password"} autoComplete="confirmPassword" {...register('confirmPassword', {
                        required: true,
                        minLength: 4,
                        validate: (value) => {
                          return value === newPassword;
                        }
                      })}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-blue-500 focus:border-blue-500 sm:text-sm sm:leading-6 p-3"
                      />
                      <div className='password_icon mr-2'>
                        {confirmPasswordShow ? <i class="ri-eye-line" style={{ cursor: 'pointer' }} onClick={() => setConfirmPasswordShow((pre) => !pre)}></i> : <i class="ri-eye-off-line" onClick={() => setConfirmPasswordShow((pre) => !pre)} style={{ cursor: 'pointer' }}></i>}
                      </div>
                      {errors.confirmPassword && errors.confirmPassword.type === "validate" != '' ? <div className="text-red-500 text-left text-xs">New password and confirm password not match!</div> : ''}
                      {errors.confirmPassword && errors.confirmPassword.type === "required" != '' ? <div className="text-red-500 text-left text-xs">Confirm Passowrd is required!</div> : ''}
                    </div>
                  </div>

                  <div className="flex items-center justify-center" style={{ marginTop: '23px' }}>
                    <Button title={'Change Password'} loading={passwordButtonLoading} buttonClass="flex w-full justify-center rounded-md bg-[#223b8b] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#223b8bda] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600" buttonType='submit' />
                  </div>
                </form>



              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default ChangePassword