import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { postLogin,getList } from "../../helper/fackBackend_Helper";

function* getCountryListResponse() {
    try {
        const response = yield call(getList, '/getCountry');
        if (response.response.Status) {  
            yield put( actions.getCountrySuccess(response.response.Data ) );
        } else {
            yield put(actions.getCountryFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getCountryFailed(error));
    }
}
function* getCountryForWebisteListResponse() {
    try {
        const response = yield call(getList, '/getCountryForWebsite');
        if (response.response.Status) {  
            yield put( actions.getCountryForWebsiteSuccess(response.response.Data ) );
        } else {
            yield put(actions.getCountryForWebsiteFailed(response.response.Message));
        }
    } catch (error) {
        yield put(actions.getCountryForWebsiteFailed(error));
    }
}
export default function* rootSaga() {
    yield all([takeEvery(actions.GET_COUNTRY, getCountryListResponse)]);
    yield all([takeEvery(actions.GET_COUNTRY_FOR_WEBSITE, getCountryForWebisteListResponse)]);
}
